import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import s from "@emotion/styled";
import { DownloadCloud, CancelIcon } from "../icons/icons";
import { getCurrentTimeStamp } from "../utils/utils";
import { isValidSkylink } from "../utils/skynet";

import ItemsContainer from "../reusable/fs/ItemsContainer";
import ItemsCounterTitle from "../reusable/fs/ItemsCounterTitle";
import File from "../features/fileSystem/File";
import PreviewModal from "../components/PreviewModal";
import Retrieve from "../components/Retrieve";
import SubTitle from "../components/SubTitle";
import useSkynetMetadata from "../utils/hooks/useSkynetMetadata";

import useStore from "../store/useStore";
const retrievedFilesSelector = (state) => state.fs.retrievedFiles;
export default function DownloadItemsList({
  setSnackbar,
}) {
  const r = useStore(retrievedFilesSelector);
  // TODO use status
  const [metadata, status, getMetadata] = useSkynetMetadata();
  const [showPrModal, setShowPrModal] = useState(false);
  const [previewList, setPreviewList] = useState([]);
  const [isValidLink, setValidLink] = useState(false);
  const [skylink, setRetrLink] = useState("");

  useEffect(() => {
    if (isValidSkylink(skylink)) {
      getMetadata(isValidSkylink(skylink));
    }
  }, [skylink]);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(`https://siasky.net/${link}`);
    setSnackbar({ icon: null, text: "Link has been copied", active: true });
  };

  // const [fl, setFl] = useState(null);
  //       setFl(retrievedFile);

  const handleChange = (event) => {
    console.log("event", event.target.value);
    setRetrLink(event.target.value);
    const isValid = isValidSkylink(event.target.value);
    if (isValid) {
      setValidLink(true);
    } else {
      setValidLink(false);
    }
  };

  const handleRetrBtnClick = async () => {
    if (isValidLink) {
      if (metadata) {
        console.log(metadata)
        const id = uuidv4();
        let retrievedFile = {
          id,
          name: metadata.filename,
          size: metadata.length,
          skylink: skylink,
          deviceModified: metadata.lastModified,
          lastModified: getCurrentTimeStamp(),
          type: metadata.subfiles[metadata.filename].contenttype,
          source: "link",
          isPrivate: false,
          webkitRelativePath: "",
        };
        let previewObject = {
          progress: 0,
          status: "ready",
          file: retrievedFile,
        };
        // TODO
        setPreviewList([previewObject]);
        console.log("prevList", previewList, retrievedFile);

        setShowPrModal(true);
      }
    } else {
      setSnackbar({
        icon: <CancelIcon />,
        text: "Link is not valid",
        active: true,
      });
    }
  };

  return (
    <Container>
      <Retrieve
        handleRetrBtnClick={handleRetrBtnClick}
        handleChange={handleChange}
        skylink={skylink}
        isValidLink={isValidLink}
      ></Retrieve>
      <SubTitle title="Previous Downloads">
        <DownloadCloud />
      </SubTitle>
      <ItemsCounterTitle name="Files" items={r} />
      <ItemsContainer>
        {r && Object.entries(r).map(([k, v], i) => (
          <File key={k} file={v} index={i} handleCopyLink={handleCopyLink} />
        ))}
      </ItemsContainer>
      <PreviewModal />
    </Container>
  );
}

const Container = s.div`
display: flex;
flex-direction: column;
flex: 1;
padding: 0 32px;
`;
