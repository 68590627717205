import React, { useState, useEffect } from "react";
import s from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { colorD } from "../style/global";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CancelIcon,
  FolderXsIcon,
  ArrowUpIcon,
  PlusIcon,
} from "../icons/icons";

import FileUploader from "../features/fileSystem/FileUploader";
import ImportingFilesList from "../features/fileSystem/ImportingFilesList";
import BasicDropdown from "../reusable/dropdown";

import useStore from "../store/useStore";

const fsSelector = (state) => state.fs;

export default React.memo(() => {
  const fs = useStore(fsSelector);
  const { onFileUploadStateChange, setDestinationContainerId, setTotalProgress, rmUploadPreview, skynetFileUpload, touch, rm } = useStore();

  const [showPrModal, setShowPrModal] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [miniPreview, setMiniPreview] = useState(false);

  useEffect(() => {
    fs.uploadPreviewList.length && setShowPrModal(true);
    !fs.uploadPreviewList.length && setTotalProgress(0)
  }, [fs.uploadPreviewList]);

  const handleClose = () => {
    setShowPrModal(false);
    setTimeout(() => {
      setIsUploaded(false);
      setMiniPreview(false);

      rmUploadPreview(null);
      // TODO total progress 0
      setTotalProgress(0)
    }, 1000);
  };

  const onFileStateChange = (f, p, s) => {
    console.log('on ifle', f, p, s)
    onFileUploadStateChange(f, p, s);
  }
  const handleAbort = (index, id) => {
    console.log('abort handle', id, index)
    handleCancel(index)
    id && rm(id)
  };
  const handleCancel = (index) => {
    rmUploadPreview(index);
  };
  const handleUpload = (index) => {
    index === undefined && setMiniPreview(true);
    const list = index === undefined ? fs.uploadPreviewList : [fs.uploadPreviewList[index]];
    const files = skynetFileUpload(list, onFileStateChange);
    // TODO abort controller file.cancel()
    Promise.all(
      files.map(async (file) => {
        try {

          const f = await file
          const d = await f.completed
          // setTimeout(() => {
          //   f.cancel()
          //   console.log('canceleed', f.cancel)
          // }, 1000)
          if (d.skylink) {
            touch(d, fs.destinationContainerId);
          }
        }
        catch {
          alert('Server Error, file was not loaded!')
        }
      })
    );
    index === undefined && setIsUploaded(true);
  };

  const onSelectChange = (dest) => {
    console.log('dest', dest)
    dest && setDestinationContainerId(dest);
  };

  return (
    <Container isActive={showPrModal} miniPreview={miniPreview}>
      {!miniPreview && (
        <StorageGroupTitle>
          <GroupTitleProgress total={fs.totalProgress} />
          <span>
            Upload {fs.uploadPreviewList.length}{" "}
            {fs.uploadPreviewList.length === 1 ? "file" : "files"}
          </span>
          <span>to</span>
          <SelectorWrapper>
            <FolderXsIcon />
            <BasicDropdown
              onSelectChange={onSelectChange}
              data={Object.values(fs.folders)}
              defaultValue={
                fs.folders[fs.currentDir]
                  ? fs.folders[fs.currentDir].name
                  : "Private space"
              }
            />
          </SelectorWrapper>
          <div onClick={() => setMiniPreview(!miniPreview)}>
            <ChevronDownIcon />
          </div>
        </StorageGroupTitle>
      )}
      <ContentWrapper miniPreview={miniPreview}>
        {!miniPreview ? (
          <ImportingFilesList cancel={handleCancel} upload={handleUpload} abort={handleAbort}/>
        ) : isUploaded ? (
          <StorageGroupTitle>
            <GroupTitleProgress total={fs.totalProgress} />
            <span onClick={() => setMiniPreview(!miniPreview)}>
              Uploaded {fs.uploadPreviewList.length}{" "}
              {fs.uploadPreviewList.length === 1 ? "file" : "files"}
            </span>
            <ViewDetails onClick={() => setMiniPreview(!miniPreview)}>
              View Details
            </ViewDetails>
            <span></span>

            <div onClick={() => handleClose()} style={{ marginLeft: "0" }}>
              <CancelIcon />
            </div>
          </StorageGroupTitle>
        ) : (
          <StorageGroupTitle>
            <GroupTitleProgress total={fs.totalProgress} />
            <span>
              Uploading {fs.uploadPreviewList.length}{" "}
              {fs.uploadPreviewList.length === 1 ? "file" : "files"}
            </span>
            <span></span>
            <div onClick={() => setMiniPreview(!miniPreview)}>
              <ChevronUpIcon />
            </div>
          </StorageGroupTitle>
        )}
      </ContentWrapper>
      {!miniPreview && (
        <BottomBtnGroup isActive={!miniPreview}>
          <CancelBtn onClick={handleClose}>
            <CancelIcon />
            <span>All</span>
          </CancelBtn>
          {!isUploaded && (
            <>
              <PlusBtn>
                <FileUploader previewList={fs.uploadPreviewList}>
                  <PlusIcon />
                </FileUploader>
              </PlusBtn>
              <UploadBtn disabled={false} onClick={() => handleUpload()}>
                <span>Upload</span>
                <ArrowUpIcon />
              </UploadBtn>
            </>
          )}
        </BottomBtnGroup>
      )}
    </Container>
  );
});

// TODO if network error, show error
const loading = keyframes`
 0% {
   background-position: 0% 50%;
 }
 50% {
   background-position: 100% 50%;
 }
 100% {
   background-position: 0% 50%;
 }
`;
//background: rgba(56, 57, 75, 0.76);
//background: ${colorD.darkPrim100};
//padding: ${(props) => (props.miniPreview ? "0 8px" : "12px")};
//${(props) => (props.miniPreview ? "border-bottom-left-radius: 40px;border-top-left-radius: 40px;" : "border-radius: 40px;")};
const Container = s.div`
background: ${colorD.darkPrim100};
position: fixed;
z-index: 11;
border-bottom-left-radius: 40px;
border-top-left-radius: 40px;
bottom: 0;
right: 0;
width: 390px;
height: ${(props) => (props.miniPreview ? "56px" : "430px")};
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
padding: 12px;
transform: ${(props) =>
  props.isActive ? "translateY(0px)" : "translateY(120px)"};
transition: all .4s ease;
@media screen and (min-width: 641px) {
margin: 0 auto;
}
@media screen and (max-width: 640px) {
width: 96%;
max-height: 456px;
bottom: 0px;
}
@media screen and (max-width: 358px) {
width: 338px;
max-height: 456px;
bottom: 0px;
}
`;
const ContentWrapper = s.div`
max-height: 100%;
${(props) => !props.miniPreview && "overflow-y: auto;"}
${(props) => props.miniPreview && "display: flex; align-items: center; height: 100%;"}
`;
const StorageGroupTitle = s.div`
margin: 0 20px 0 24px;
display: flex;
flex: 0 100%;
font-size: 16px;
> span {
display: flex;
align-items: center;
font-weight: 600;
font-size: 16px;
&:nth-of-type(1) {
padding-right: 4px;
color: ${colorD.lightPrim0};
}
&:nth-of-type(2) {
color: #6A6B7C;
@media screen and (max-width: 414px) {
padding-left: 0px;
}
padding-left: 8px;
margin-right: 12px;
text-overflow: ellipsis;
overflow: hidden;
> svg {
margin-right: 8px;
path {
stroke: ${colorD.darkPrim80};
}
}
}
}
> div {
cursor: pointer;
&:last-of-type{
margin-left: auto;
display: flex;
align-items: center;
}
}
position: relative;
`;
const GroupTitleProgress = s.div`
position: absolute;
bottom: -17px;
width: ${(props) => props.total}%;
background: ${colorD.accent100};
background: linear-gradient(to right, #6C69FF 25%, #50F5E2 50%, #5350F5 75%, #F550D1 100%);
background-size: 1000px 3px;
animation: ${loading} 3s ease-in-out infinite;
height: 3px;
`;
const BottomBtnGroup = s.div`
transition: all .4s ease;
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
position: absolute;
left: 50%;
transform: translateX(-50%);
padding: 12px 0 4px 0;
bottom: 0px;
display: flex;
margin-top: auto;
justify-content: flex-end;
width: 100%;
@media screen and (min-width: 641px) {
border-bottom-left-radius: 16px;
border-bottom-right-radius: 16px;
}
@media screen and (max-width: 640px) {
justify-content: space-around;
}
> button {
border: 2px solid rgba(56, 57, 75, 0.5);
border-radius: 24px;
height: 56px;
display: flex;
border: none;
height: 56px;
cursor: pointer;
justify-content: center;
align-items: center;
> span {
font-weight: 500;
font-size: 16px;
}
}
`;
const CancelBtn = s.button`
width: 138px;
background: transparent;
margin-left: 8px;
margin-right: auto;
> span {
color: #FDFDFD;
margin-left: 13px;
opacity: .6;
}
path {
opacity: .6;
stroke: #FDFDFD;
fill: #FDFDFD;
}
@media screen and (max-width: 640px) {
width: 120px;
}
`;
const PlusBtn = s.button`
width: 72px;
background: transparent;
margin-right: 9px;
path {
opacity: .6;
stroke: #FDFDFD;
fill: #FDFDFD;
}
`;
const UploadBtn = s.button`
width: 149px;
margin-right: 8px;
color: ${colorD.lightPrim0};
background: transparent;
path {
stroke: ${colorD.lightPrim0};
fill: ${colorD.lightPrim0};
}
@media screen and (max-width: 640px) {
width: 120px;
}
`;
const SelectorWrapper = s.div`
display: flex;
justify-content: center;
border-radius: 8px;
padding: 8px 8px;
&:hover {
border-radius: 8px;
background: ${colorD.darkPrim40};
transition: all .4s ease;
}
> select {
cursor: pointer;
text-overflow: ellipsis;
max-width: 200px;
background-color: transparent;
outline: none;
appearance: none;
transition: all .4s ease;
border: none;
color: ${colorD.lightPrim0};
> option {
max-width: 80px;
}
@media screen and (max-width: 414px) {
max-width: 84px;
}
}
`;
const ViewDetails = s.div`
margin-left: auto;
cursor: pointer;
color: ${colorD.lightPrim90};
`;



  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (isUploaded && miniPreview) {
  //       handleClose();
  //     }
  //   }, 4000);
  //   if (!miniPreview) {
  //     clearTimeout(timeout);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [miniPreview, isUploaded]);

  // TODO Reject files larger than our hard limit of 1 GB with proper message
  // if (file.size > bytes("1 GB")) {
  //   onFileStateChange(fil, { status: "error", error: "This file size exceeds the maximum allowed size of 1 GB." });
  //   return;
  // }

  // useEffect(() => {
  //   setSelectedDir(fs.currentDir);
  // }, [fs.currentDir]);
  // files.forEach( ({ fileObj }) => {
  //     //add [fileObj.id]: fileObj to FS for cancel purposes
  //     // show loader fileObj
  //     console.log('loaded fileobj', fileObj)
  //     //setPreviewList((prevFiles) => [...fs.uploadPreviewList.map((file) => ({file, status: 'uploading'})), ...prevFiles])
  // })

  //   const ad = Promise.all(
  //     files.map(
  //       (file) =>
  //       file.completed &&
  //         file.completed.then((newFile) => {
  //           console.log("completed", newFile);
  //           if (file) {
  //             touch(newFile, selectedDir);
  //             //setFs(data);
  //           } else {
  //             console.warn("canceled");
  //             // remove [file.id] from FS (canceled)
  //           }
  //           //console.log('rm lodaer for file', file.fileObj)
  //           // remove loader for this file
  //         })
  //     )
  //   );
  // console.log(" where it comes from ??? i", ad);
  //   await ad;
  //setTotalProgress(0);

  // if (list[0].file instanceof File) {
  // } else {
  // const data = list.map(({ file }) => {
  //   console.log("PreviewModal.js", file.file);
  //   fs.dirContents[fs.currentDir] = [
  //     ...fs.dirContents[fs.currentDir],
  //     file.id,
  //   ];
  //   fs.files = Object.assign(fs.files, { [file.id]: file });
  //   fs.r = Object.assign(fs.r, { [file.id]: file });
  //   //const n = Object.assign({}, fs);
  //   //const data = saveFsItem(fs, file, selectedDir);
  //   //setFs(n);
  //   //return n;
  // });
  // i === undefined && setUploaded(true);
  // //await saveToDBWithoutEncryption(data);
  //}
