import React from "react";
import s from "@emotion/styled";
import {
  CornerRightIcon,
  CornerDownIcon,
  LockIcon,
  NavFolderIcon,
  SoonIcon,
  DriveIcon,
  EyeIcon,
  DropboxIcon,
} from "../../icons/icons";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { breakpoint, colorD } from "../../style/global";

import useStore from "../../store/useStore";

const fsSelector = (state) => state.fs;

export default function Nav() {
  const fs = useStore(fsSelector);
  const tabs = [
    {
      url: "/",
      name: "Private space",
      icon: <LockIcon />,
    },
    {
      url: "/shared",
      name: "Shared files",
      icon: <EyeIcon />,
    },
    {
      url: "/downloads",
      name: "Downloads",
      icon: null,
    },
  ];
  const [isSubOpen, setSubOpen] = useState({ state: false, i: 0 });
  const folders = Object.values(fs.folders);
  const size = useWindowSize();

  const linkStyl = {
    color: `${colorD.darkPrim90}`,
    borderRight: `2px solid ${colorD.accent100}`,
  };

  return (
    <NavWrapper>
      {tabs.map((t, i) => (
        <React.Fragment key={i}>
          <NavLink exact key={t.name} to={t.url} activeStyle={linkStyl}>
            <span>
              <div onClick={() => setSubOpen({ state: !isSubOpen.state, i })}>
                {isSubOpen.state && i === isSubOpen.i ? (
                  <CornerDownIcon />
                ) : (
                  <CornerRightIcon />
                )}
              </div>
              {t.name}
              {t.icon}
            </span>
          </NavLink>
          {t.url === "/" && isSubOpen.i === 0 && isSubOpen.state ? (
            <Archives width={size.width} breakpoint={breakpoint}>
              {folders.map((f) => (
                <NavLink
                  exact
                  key={f.id}
                  to={`/folder/${f.id}`}
                  activeStyle={linkStyl}
                >
                  <span>
                    <NavFolderIcon />
                    <span>{f.name}</span>
                  </span>
                </NavLink>
              ))}
            </Archives>
          ) : null}
        </React.Fragment>
      ))}

      <BackupTitle>BACKUPS</BackupTitle>
      <NavLink exact to={'/gdrive'} activeStyle={linkStyl}>
        <BackupItem>
          <DriveIcon />
          <span>G-Drive</span>
        </BackupItem>
      </NavLink>
      <BackupItem>
        <DropboxIcon />
        <span>Dropbox</span>
        <SoonIcon />
      </BackupItem>
    </NavWrapper>
  );
}

// @media screen and (max-width: 640px) {
// display: flex;
// }
const NavWrapper = s.nav`
flex: 0 100%;
padding: 24px 0 24px 24px;
> a {
display: flex;
flex-direction: column;
text-decoration: none;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
padding-right: 24px;
color: ${colorD.darkPrim60};
 > span {
  display: flex;
  transition: background 0.33s ease-out;
  align-items: center;
  padding: 8px 0;
  flex: 1;
  border-radius: 8px;
  &:hover {
  background: ${colorD.darkPrim10};
  border-radius: 8px;
 }
 > div {
  display: flex;
  align-items: center;
  > svg {
    margin: 0 8px 0 8px;
    border-radius: 6px;
    &: hover {
     background: ${colorD.darkPrim20};
     border-radius: 6px;
    }
   }
  }
 }
}
`;

const Archives = s.div`
padding-left: 30px;
display: flex;
flex-direction: column;
> a {
max-width: 152px;
text-decoration: none;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
padding-right: 12px;
color: ${colorD.darkPrim60};
 > span {
   display: flex;
   transition: background 0.33s ease-out;
   align-items: center;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   padding: 6px 16px 6px 6px;
   border-radius: 8px;
   > svg {
    margin: 0 8px 0 0px;
    min-width: 20px;
   }
   > span {
    padding-bottom: 2px;
    text-overflow: elipsis;
    overflow: hidden;
   }
   &:hover {
    transition: background 0.33s ease-out;
    background: ${colorD.darkPrim10};
    border-radius: 8px;
   }
 }
}
`;

const BackupTitle = s.div`
margin: 22px 0 26px 0;
letter-spacing: 3px;
color: ${colorD.darkPrim20};
font-size: 12px;
`;
const BackupItem = s.div`
cursor: not-background;
display: flex;
align-items: center;
color: ${colorD.darkPrim60};
font-weight: 500;
font-size: 16px;
line-height: 16px;
margin-bottom: 12px;
> svg {
margin-right: 8px;
&:nth-of-type(2) {
margin-left: 10px;
}
}
`;
