import React from "react";
import s from "@emotion/styled";

import Header from "../components/Header";
import Sidebar from "../features/navigation/Sidebar";
import useWindowSize from '../utils/hooks/useWindowSize';

export default React.memo(({children}) => {
  const { width, height } = useWindowSize();
  return (
    <MainContainer width={width} height={height}>
      <Header />
      <Content>
        <Sidebar />
        <MainContent>
          {children}
        </MainContent>
      </Content>
    </MainContainer>
  );
});

const MainContainer = s.div`
background: #1D1E29;
display: flex;
width: ${({width}) => `${width}px`};
height: ${({height}) => `${height}px`};
flex-direction: column;
position: relative;
transition: all 0.4s ease;
`;
const MainContent = s.div`
width: 100%;
position: relative;
transition: all 0.4s ease;
display: flex;
overflow: hidden;
`;
const Content = s.div`
flex: 1;
display: flex;
justify-content: flex-start;
align-items: stretch;
border-top-left-radius: 40px;
border-top-right-radius: 40px;
background: #242533;
@media screen and (max-width: 641px) {
width: 100%;
flex-direction: column;
}
`;
// @media screen and (max-width: 375px) {
// max-height: none;
// }
//cursor and outline.
// &::selection {
//   color: red;
//   background: yellow;
// }
// @media screen and (min-width: 641px) {
// max-height: 87vh;
// }

// {size.width <= breakpoint.mds && (
//   <Search
//     isSidebarActive={isSidebarActive}
//     setIsSidebarActive={setIsSidebarActive}
//   />
// )}
// {size.width <= breakpoint.mds && (
//   <Sidebar
//     isSidebarActive={isSidebarActive}
//     setIsSidebarActive={setIsSidebarActive}
//   />
// )}

// {size.width >= breakpoint.mds && (
// )}
