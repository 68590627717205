import s from "@emotion/styled";
import { colorD } from "../style/global";
import { ArrowDownIcon } from "../icons/icons";
import RoundBtn from "../components/RoundBtn";

export default function Retrieve({
  handleRetrBtnClick,
  handleChange,
  isValidLink,
  skylink,
}) {
  return (
    <Container>
      <Lable>Downloads</Lable>
      <h2>Download from skynet</h2>
      <h3>Drop the link people shared with you</h3>
      <Input
        placeholder="Enter skylink to retrieve files..."
        value={skylink}
        onChange={handleChange}
      />
      <div onClick={() => handleRetrBtnClick()}>
        <RoundBtn isValidLink={isValidLink}>
          <ArrowDownIcon />
        </RoundBtn>
      </div>
    </Container>
  );
}

const Container = s.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 24px 0 65px 0;
color: ${colorD.darkPrim90};
> h2  isValidLink{
font-size: 24px;
font-weight: 600;
line-height: 33.6px;
}
> h3 {
margin-top: 2px;
font-size: 18px;
font-weight: 500;
line-height: 25.2px;
}
`;
const Lable = s.h1`
align-self: flex-start;
font-size: 15px;
font-weight: 600;
line-height: 21px;
color: ${colorD.darkPrim40};
`;
const Input = s.input`
background: #38394B;
border: none;
border-radius: 16px;
padding: 16px;
width: 400px;
margin: 32px 0 34px 0;
color: ${colorD.darkPrim80};
&:focus {
    outline: none !important;
    box-shadow: 0 0 16px rgba(87, 84, 247, 0.99);
}
`;
