//https://developers.google.com/identity/sign-in/web/reference#gapiauth2signinoptions
// google auth2 api refference
import { useEffect, useState } from 'react';
import { GAPI_SCRIPT_URL } from '../../constants';
import useScript from './useScript';


export default function useGapi({
  apiKey,
  clientId,
  discoveryDocs,
  scope = 'profile https://www.googleapis.com/auth/drive.readonly',
  cookiePolicy = 'single_host_origin',
  fetchBasicProfile = true,
  persist = true
}) {
  if (!clientId) throw new Error("Please provide required fields: 'clientId'");
  if (!apiKey) throw new Error("Please provide required fields: 'apiKey'");
  const status = useScript(GAPI_SCRIPT_URL)
  // Possible status is "idle", "ready", "loading", "error"
  if (status === "error") console.warn("Google script can't be loaded");

  const [client, setClient] = useState({undefined});
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [profile, setProfile] = useState({
    auth: {
      tokenObj: undefined,
      tokenId: undefined,
      accessToken: undefined,
      scope: undefined,
      expiresAt: undefined,
    },
    profile: {
      googleId: undefined,
      email: undefined,
      fullName: undefined,
      givenName: undefined,
      familyName: undefined,
      imageUrl: undefined,
    }
  });

  const createUserProfileObject = (currentUser) => {
    const user = currentUser || window.gapi.auth2.getAuthInstance().currentUser.get();
    const basicProfile = user.getBasicProfile();
    const auth = user.getAuthResponse();
    return {
      auth,
      profile: {
        googleId: basicProfile.getId(),
        email: basicProfile.getEmail(),
        fullName: basicProfile.getName(),
        givenName: basicProfile.getGivenName(),
        familyName: basicProfile.getFamilyName(),
        imageUrl: basicProfile.getImageUrl(),
      }
    }
  }
  const updateSigninStatus = (isUserSignedIn) => {
    if(isUserSignedIn) {
      setIsSignedIn(isSignedIn);
      const gProfile = createUserProfileObject()
      setProfile(gProfile);
    }
  }

  useEffect(() => {
    if (status === 'ready') {
      let listenerContext;
      window.gapi.load('client:auth2', () => {
        window.gapi.client.init({
          apiKey,
          clientId,
          discoveryDocs,
          scope,
        }).then(() => {
          setClient(window.gapi.client);
          const auth = window.gapi.auth2.getAuthInstance()
          listenerContext = auth.isSignedIn.listen(updateSigninStatus);
          // Handle the initial sign-in state.
          //console.log('auth !', auth.currentUser.get())
          updateSigninStatus(auth.isSignedIn.get());
        })
      })
      return () => {
        console.log('unsubscribe')
        listenerContext?.remove();
      };
    }
    else {
      return;
    }
  }, [status])

  const signIn = async (options) => {
    try {
      const p = await window.gapi.auth2.getAuthInstance().signIn(options)
      console.log('p', p)
      return createUserProfileObject(p)
    } catch(err) {
      return err
    }
  }
  const signOut = async () => await window.gapi.auth2.getAuthInstance().signOut()

  //console.log('signin gapi', window.gapi.auth2.getAuthInstance().currentUser.getId())
  //return createUserProfileObject(window.gapi.auth2.getAuthInstance().signIn(options))
    //  const auth2 = window.gapi.auth2.getAuthInstance()

    // if (!auth2) return false

    // await auth2.signOut()
    // auth2.disconnect()

    // return true

  return { signIn, signOut, isSignedIn, profile, client }
}
