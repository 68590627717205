import React, { useEffect } from "react";
import s from "@emotion/styled";
import { colorD } from "../style/global";
import { NoSearchResultsIcon } from "../icons/icons";
import StorageUsageDescription from "../components/StorageUsageDescription";
import PreviewModal from "../components/PreviewModal";
import FsItems from "../features/fileSystem/FsItems";
import UploaderMenu from "../components/UploaderMenuButtons";
import FsItemPreviewModal from "../features/preview/FsItemPreviewModal";
import ContentTitle from '../features/fileSystem/ContentTitle';
import Layout from "../components/Layout";
import { useRouteMatch } from "react-router-dom";
import useStore from "../store/useStore";

const searchListSelector = (state) => state.searchList;
const fsItemsSelector = (state) => state.fs.currentDirItems?.mixed;
const searchInputSelector = (state) => state.searchInput;

export default React.memo(({ setSnackbar }) => {
  const fsItems = useStore(fsItemsSelector);
  const searchList = useStore(searchListSelector);
  const searchInput = useStore(searchInputSelector);

  const { setCurrentDir, setCurrentDirItems } = useStore();


  const match = useRouteMatch('/folder/:id')
  const containerId = match ? match.params.id : 'root';

  console.log('match', match)
  console.log('contId', containerId)
  useEffect(() => {
    console.log('math bef', match)
    setCurrentDir(containerId);
    setCurrentDirItems(containerId);
  }, []);

  useEffect(() => {
    setCurrentDir(containerId)
    setCurrentDirItems(containerId);
  }, [match?.params?.id])


  // useEffect(() => {
  //   !!previewList.length && setShowPrModal(true);
  // }, [previewList]);

  // useEffect(() => {
  //   const res = !!searchList.length ? searchList : fs.currentDirItemsList;
  //   setStateList(res);
  // }, [searchList]);

  return (
    <Layout>
      <StorageUserFiles>
        <ContentTitle containerId={containerId} title='Private space' />
        {searchInput && !searchList.length ? (
          <NoSearchResults>
            <NoSearchResultsIcon />
            <span>No results found</span>
          </NoSearchResults>
        ) : !fsItems.length ? (
          <StorageUsageDescription />
        ) : (
          <FsItems setSnackbar={setSnackbar} />
        )}
        <PreviewModal />
      </StorageUserFiles>
      <FsItemPreviewModal setSnackbar={setSnackbar} />
      <UploaderMenu setSnackbar={setSnackbar} />
    </Layout>
  );
});

const StorageUserFiles = s.div`
transition: all 1s ease;
display: flex;
flex-direction: column;
flex: 1;
padding: 0 32px;
align-items: flex-start;
@media screen and (max-width: 640px) {
padding: 6 px;
}
@media screen and (max-width: 375px) {
padding: 6px;
}
`;

const NoSearchResults = s.div`
display:flex;
justify-content: center;
align-items: center;
> span {
margin-left: 24px;
color: ${colorD.darkPrim60};
}
`;
//{i < parents.length - 1 ? " " : null}
// TODO
//
// {!searchList.length ? currentDir ? currentDir.name !== 'My Uploads' ?
//  <Back onClick={() => history.goBack()}>
//      <ArrowUpIcon />
//  </Back> : <span>{'home/'}</span> : null : null}
//
// <FilesCountWrapper>
//     {pr && !!pr.length && <span>{pr.length} files in preview</span>}
//     {!!searchList.length
//      ? <span>{`${stateList.length} ${stateList.length === 1 ? 'item' : 'items'} found`}</span>
//      : <span>{files.length} files in {folders.length} folders</span>}
// </FilesCountWrapper>
