import React from 'react'
import s from "@emotion/styled";
import { colorD } from "../../style/global";
import {
  CheckmarkIcon,
  CancelIcon,
  FileIcon,
  UploadIcon,
} from "../../icons/icons";
import { formatSizeUnits } from "../../utils/utils";
import useStore from "../../store/useStore";

const fsSelector = (state) => state.fs;

export default React.memo(({ cancel, upload, abort }) => {
  const fs = useStore(fsSelector);
  return (
    <Container>
      {fs.uploadPreviewList &&
        fs.uploadPreviewList.map((x, i) => (
          <StorageItem key={i} style={{ maxWidth: "100%" }}>
            {x.status === "ready" && (
              <CircleWrapper onClick={() => cancel(i)}>
                <CancelIcon />
              </CircleWrapper>
            )}
            <StorageItemIcon>
              <FileIcon />
              <span>{x.fileObj.type.replace(/.*\//, "").toUpperCase()}</span>
            </StorageItemIcon>
            <StorageItemInfo>
              <StorageItemName>
                {x.fileObj.webkitRelativePath !== ""
                  ? x.fileObj.webkitRelativePath
                  : x.fileObj.name}
              </StorageItemName>
              <StorageItemInfoBottom>
                <StorageItemSize>
                  {formatSizeUnits(x.fileObj.size)}
                </StorageItemSize>
              </StorageItemInfoBottom>
            </StorageItemInfo>

            <ItemState status={x.status}>
              <div>
                {x.status === "ready" ? null : x.status === "processing" ? "Processing" : x.status === "done" ? "Uploaded" : "Uploading"}
              </div>
              <div>
                {x.status !== "done" && x.status !== "ready"
                  ? `${Math.round(x.progress.toFixed(2) * 100)}%`
                  : null}
              </div>
            </ItemState>
            {x.status === "ready" ? (
              <CircleWrapper onClick={() => upload(i)}>
                <UploadIcon />
              </CircleWrapper>
            ) : x.status === "done" ? (
              <CircleWrapper onClick={() => cancel(i)}>
                <CheckmarkIcon />
              </CircleWrapper>
            ) : (
              <CircleWrapper onClick={() => abort(i, x.fileObj.id)}>
                <svg height="36" width="36">
                  <Circle
                    progress={Math.round(x.progress.toFixed(2) * 100)}
                    cx="18"
                    cy="18"
                    r="17"
                    stroke="#ABACBE"
                    strokeWidth="2"
                    fillOpacity="0"
                  />
                </svg>
                <CancelIcon />
              </CircleWrapper>
            )}
          </StorageItem>
        ))}
    </Container>
  );
})

const Container = s.div`
flex: 0 100%;
align-self: flex-start;
margin-top: 24px;
padding-bottom: 86px;
transition: all .4s ease;
> * {
transition: all .4s ease;
}
`;

const StorageItem = s.div`
display: flex;
align-items: center;
justify-content: stretch;
background: ${colorD.darkPrim0};
border-radius: 12px;
font-size: 13px;
transition: all 0.43s ease;
padding: 16px 4px 16px 16px;
&:hover {
background: ${colorD.darkPrim20};
}
@media screen and (min-width: 641px) {
max-width: 300px;
min-width: 300px;
}
@media screen and (max-width: 640px) {
padding: 12px 4px 12px 16px;
}
@media screen and (max-width: 376px) {
padding: 12px 4px 12px 16px;
}
> a {
display: flex;
align-items: center;
justify-content: stretch;
color: #5754F7;
text-decoration: none;
underline: none;
}
`;
const StorageItemInfo = s.div`
display: flex;
margin-right: auto;
flex-direction: column;
@media screen and (max-width: 376px) {
margin-left: 0px;
}
`;
const StorageItemInfoBottom = s.div`
display: flex;
color: ${colorD.darkPrim80};
`;
const StorageItemName = s.span`
text-overflow: ellipsis;
overflow: hidden;
font-weight: 600;
padding-bottom: 4px;
white-space: nowrap;
font-size: 14px;
color: #FCFEFD;
cursor: pointer;
@media screen and (min-width: 358px) {
max-width: 170px;
}
@media screen and (min-width: 641px) {
max-width: 220px;
}
`;

const StorageItemSize = s.div`
flex: 1;
`;
const StorageItemIcon = s.div`
margin-right: 16px;
display: flex;
align-items: center;
justify-content: center;
color: ${colorD.lightPrim0};
height: 20px;
position: relative;
> span {
font-size: 8px;
position: absolute;
text-align: center;
left: 1px;
width: 30px;
overflow: hidden;
right: 0;
bottom: 0;
}
`;
const ItemState = s.div`
display: flex;
justify-content: center;
align-items: flex-end;
flex-direction: column;
margin-right: 16px;
> div {
color: ${colorD.darkPrim80};
}
`;
const Circle = s.circle`
stroke-dasharray: 1000;
stroke-dashoffset: ${(props) => {
  //console.log("props.prog", props.progress);
  return 1000 - props.progress;
}};
`;
const CircleWrapper = s.button`
position: relative;
border-radius: 100%;
border: none;
height: 36px;
width: 36px;
margin-right: 12px;
background: ${colorD.darkPrim0};
transition: border .2s linear;
border: 2px solid ${colorD.darkPrim0};
> svg, div {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}
&:active {
}
&:hover {
border: 2px solid ${colorD.darkPrim80};
transition: border .1s linear;
}
`;
