import React from 'react'
import { middleEllipsis } from "../../utils/utils";
import s from "@emotion/styled";
import { colorD } from "../../style/global";

export default React.memo(({name}) => ( <Name>{middleEllipsis(name)}</Name>))

const Name = s.div`
font-weight: 700;
font-size: 16px;
min-height: 20px;
color: ${colorD.darkPrim90};
margin-bottom: 8px;
transition: color 0.43s ease-out;
`;
