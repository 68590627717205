import React from "react";
import { Switch, Route } from "react-router-dom";

import StorageRoute from "../../components/StorageRoute";
import DownloadsRoute from "../../components/DownloadsRoute";
import GoogleBackupRoute from "../../components/GoogleBackupRoute";
import PrivateRoute from '../../components/PrivateRoute';
import LoginRoute from '../login/LoginRoute';
import PreviewRoute from '../preview/PreviewRoute';
import PrivacyPolicy from "../../components/PrivacyPolicy";
import TermsOfUse from "../../components/TermsOfUse";

// TODO NOMATCH route
export default React.memo(({ setSnackbar }) => {
  return (
    <Switch>
      <Route path="/privacy-policy" exact render={() => <PrivacyPolicy />} />
      <Route path="/terms-of-use" exact render={() => <TermsOfUse />} />
      <Route
        path="/login"
        render={() => (
          <LoginRoute setSnackbar={setSnackbar} />
        )}
      />
      <Route
        path="/preview/:id"
        render={() => (
          <PreviewRoute setSnackbar={setSnackbar} />
        )}
      />

      <PrivateRoute path="/" exact>
        <StorageRoute setSnackbar={setSnackbar} />
      </PrivateRoute>
      <PrivateRoute path="/folder/:id" >
        <StorageRoute setSnackbar={setSnackbar} />
      </PrivateRoute>
      <PrivateRoute path="/downloads">
        <DownloadsRoute setSnackbar={setSnackbar} />
      </PrivateRoute>
      <PrivateRoute path="/shared">
        <StorageRoute setSnackbar={setSnackbar} />
      </PrivateRoute>
      <PrivateRoute path="/gdrive" >
        <GoogleBackupRoute setSnackbar={setSnackbar} />
      </PrivateRoute>
    </Switch>
  );
});
