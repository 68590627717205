import React from "react";
import s from "@emotion/styled";
import useStore from "../../store/useStore";

export default React.memo(({ children }) => {
  const { setUploadPreview } = useStore();

  const handleFileInput = (e) =>
    Array.from(e.target.files).forEach((file) => setUploadPreview(file));
  return (
    <Container>
      <Label htmlFor="file-upload">
        <IconWrapper>{children}</IconWrapper>
        <input
          type="file"
          id="file-upload"
          multiple
          onChange={handleFileInput}
        />
      </Label>
    </Container>
  );
});

const Container = s.form`
width: 100%;
height: 100%;
`;
const IconWrapper = s.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;
const Label = s.label`
width: 100%;
height: 100%;
display: inline-block;
cursor: pointer;
input[type="file"] {
display: none;
}
`;
