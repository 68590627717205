import React, { useState } from "react";
import "./index.css";

import Routes from "./features/navigation/Routes";
import Snackbar from "./reusable/snackbar";

//import Menu from './ContextMenu'
// TODO make context manu on the main field
//<Menu outerRef={containerRef} />
//    const containerRef = useRef(null);
// ref={containerRef}

export default React.memo(() => {
  const [snackbar, setSnackbar] = useState({
    text: "Success!",
    icon: null,
    active: false,
  });
  return (
    <>
      <Routes setSnackbar={setSnackbar} />
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar}></Snackbar>
    </>
  );
});
