import React from "react";
import s from "@emotion/styled";
import { colorD } from "../../style/global";
import { ArrowLeftIcon } from "../../icons/icons";
import Breadcrumbs from "./Breadcrumbs";
import Border from "../../reusable/border";
import { useHistory } from "react-router-dom";

import useStore from "../../store/useStore";

const fsSelector = (state) => state.fs;
const searchListSelector = (state) => state.searchList;
const searchInputSelector = (state) => state.searchInput;


// TODO refoctor filterbar
//import FiltersBar from "../components/FiltersBar";
// {searchInput && (
//   <FiltersBar
//     fs={fs}
//     containerId={containerId}
//     setSearchTerm={setSearchTerm}
//     searchList={searchList}
//     setResultsList={setResultsList}
//   />
// )}
//
export default React.memo(({containerId, title, isPreview}) => {
  const history = useHistory();
  const fs = useStore(fsSelector);
  const searchList = useStore(searchListSelector);
  const searchInput = useStore(searchInputSelector);

  const currentDir = fs.folders[containerId];
  return (
    <>
        <Container searchInput={searchInput} searchList={searchList}>
          <Breadcrumbs />
          {!searchInput && (
            <>
              {containerId !== "root" && (
                <Back onClick={() => history.goBack()}>
                  <ArrowLeftIcon />
                </Back>
              )}
              <CurrentDir>
                {containerId !== "root" && currentDir
                  ? currentDir.name : title}
              </CurrentDir>
            </>
          )}
        </Container>
        {!searchInput && !isPreview && <Border />}
    </>
  )
})


const Container = s.div`
display: flex;
flex-wrap: wrap;
overflow: hidden;
overflow: hidden;
text-overflow: elipsis;
margin: 24px 0;
@media screen and (max-width: 375px) {
margin: ${(props) =>
  props.searchInput && !props.searchList.length
    ? "0px 0 12px 24px"
    : "52px 0 12px 24px"} ;
}
`;
const CurrentDir = s.div`
@media screen and (max-width: 640px) {
max-width: 300px;
}
color: ${colorD.lightPrim0};
text-overflow: ellipsis;
overflow: hidden;
font-weight: 700;
font-size: 16px;
display: flex;
margin-right: auto;
align-items: center;
padding-bottom: 2px;
`;

const Back = s.span`
max-height: 24px;
margin-right: 4px;
cursor: pointer;
> svg {
margin-right: 8px;
}
`;
