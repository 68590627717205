import React from 'react'
import { formatSizeUnits } from "../../utils/utils";
import s from "@emotion/styled";

export default React.memo(({size}) => (<Size>{formatSizeUnits(size)}</Size>))

const Size = s.div`
padding: 0 0 0 8px;
flex: 1;
`;
