import React from 'react';
import s from "@emotion/styled";
import { colorD } from "../style/global";
import useGapi from "../utils/hooks/useGapi";
import { formatSizeUnits } from "../utils/utils";

import shallow from "zustand/shallow";
import useStore from "../store/useStore";

export default React.memo(({ isActive, setIsActive }) => {
  const [user, fs] = useStore((state) => [state.user, state.fs], shallow);
  const { authenticate, clearFs } = useStore();

  const { signOut } = useGapi({
    apiKey: "AIzaSyDY_2CY1O06FqR7z879uw7dMBhdWSwnsrY",
    clientId: "114587562380-eqvrt6di696up03i9cu39vodn17l69sr.apps.googleusercontent.com",
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  })

  // $ calculate reminding storage
  const amount = Object.values(fs.files).reduce((acc, c) => acc + c.size, 0);
  const a = 10000000000;
  const b = Number(amount);
  const amountTaken = Math.ceil(Math.abs(((a - b) / (a + b)) * 100 - 100));
  const amountLeft = ((a - b) / (a + b)) * 100;

  const logoutHandler = () => {
    authenticate(false);
    signOut();
    setIsActive(false);
    clearFs();
  };

  return (
    <Container isActive={isActive}>
      <UserInfo>
        <UserName>
          <div>{user.gp && user.gp.name}</div>
        </UserName>
        <SizeAmount>
          <span>{formatSizeUnits(amount)}</span> of 10GB
          <AmountStripeWrapper>
            <AmountStripe amountTaken={amountTaken} />
          </AmountStripeWrapper>
          <span>
            {amountLeft === 100.0
             ? amountLeft.toFixed(0)
             : amountLeft.toFixed(2)}
            % of free storage left
          </span>
        </SizeAmount>
        <Logout onClick={() => logoutHandler()}>Logout</Logout>
      </UserInfo>
    </Container>
  );
})

const Container = s.div`
positon: absolute;
z-index: 7;
background: ${colorD.darkPrim20};
position: fixed;
border-radius: 40px;
top: -40px;
right: 0px;
width: 414px;
max-width: 414px;
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transform: ${(props) =>
  props.isActive ? "translateY(100px)" : "translateY(0px)"};
transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
@media screen and (max-width: 640px) {
top: -10px;
right: 12px;
}
`;

const UserInfo = s.div`
padding: 24px 32px;
color: ${colorD.lightPrim0};
`;
const UserName = s.div`
padding-bottom: 84px;
font-weight: 600;
font-size: 16px;
> span {
color: ${colorD.darkPrim90};
padding-right: 8px;
}
> div {
overflow: hidden;
text-overflow: ellipsis;
}
`;
const Logout = s.div`
cursor: pointer;
color: ${colorD.lightPrim0};
text-align: right;
font-weight: 600;
margin-top: 68px;
background: ${colorD.accent100};
width: max-content;
margin-left: auto;
padding: 8px 12px;
border-radius: 16px;
`;

const AmountStripeWrapper = s.div`
height: 6px;
background: ${colorD.darkPrim40};
border-radius: 20px;
width: 100%;
margin: 8px 0;
`;
const AmountStripe = s.div`
height: 100%;
width: ${(props) => (props.amountTaken ? `${props.amountTaken}` : "0")}%;
background: linear-gradient(261.23deg, #6C69FF 2.23%, #5350F5 56.68%);
border-radius: 20px;
`;
const SizeAmount = s.div`
font-size: 14px;
`;
