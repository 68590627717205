import React, { useRef } from "react";
import { colorD } from "../../style/global";
import s from "@emotion/styled";
import FsItemPreviewModal from "./FsItemPreviewModal";
import Header from '../../components/Header';
import ContentTitle from '../fileSystem/ContentTitle';
import Pdf from './Pdf';

import useStore from "../../store/useStore";
import useWindowSize from '../../utils/hooks/useWindowSize';
import { SIA_PORTAL } from "../../constants";

const fsItemPreviewSelector = (state) => state.fs.itemPreview;

const imageRegExp = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
const audioRegExp = /(mpeg|ogg|wav|aacp?)$/i;
const videoRegExp = /(mp4|webm|ogv)/i;
const pdfRegExp = /pdf$/;


export default React.memo(({setSnackbar}) => {
  const { item } = useStore(fsItemPreviewSelector);
  const { width, height } = useWindowSize();
  const modalWidthRef = useRef(0);
  //- modalWidthRef?.style.innerWidth
  console.log('width', modalWidthRef)
  const mediaStyle = {
    height: `${height - 180}px`,
    width: `${width - modalWidthRef.current - 40}px`,
    objectFit: 'contain'
  }
    //width: `${width - modalWidthRef.current - 100}px`,

  const link = `${SIA_PORTAL}${item.skylink}`
  const previewObj = () => {
    switch (true) {
      case imageRegExp.test(item.type):
        console.log("item type is image", item.type);
        return <img src={link}  alt="" style={mediaStyle} />
      case audioRegExp.test(item.type):
        console.log("item type is audio", item.type);
        return <audio src={link} alt="" style={mediaStyle} />
      case videoRegExp.test(item.type):
        console.log("item type is video", item.type);
        return <video src={link}  alt="" style={mediaStyle} />
      case pdfRegExp.test(item.type):
        console.log("item type is pdf", item.type);
        return <Pdf file={link} style={mediaStyle} />
      default:
        console.log("Didn't match");
        break;
    }
  }
  return (
    <Container width={width} height={height}>
      <Header />
      <Content>
        <PreviewContainer>
          <ContentTitle isPreview={true} containerId={item.id} title='Private space' />
          <PreviewItem height={height}>
            {previewObj()}
          </PreviewItem>
        </PreviewContainer>
        <div ref={el => modalWidthRef.current = el?.clientWidth} >
          <FsItemPreviewModal isPreview={true} setSnackbar={setSnackbar} />
        </div>
      </Content>
    </Container>
  )
})

const Container = s.div`
display: flex;
flex-direction: column;
width: ${({width}) => `${width}px`};
height: ${({height}) => `${height}px`};
background: ${colorD.darkPrim100};
`
const Content = s.div`
padding-left: 32px;
display: flex;
flex: 1;
background: ${colorD.darkPrim0};
border-top-left-radius: 40px;
border-top-right-radius: 40px;
`
const PreviewContainer = s.div`
flex: 1;
display: flex;
flex-direction: column;
`
const PreviewItem = s.div`
overflow:  hidden;
display: flex;
justify-content: center;
align-items: flex-start;
`
