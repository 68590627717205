import React from "react";
import s from "@emotion/styled";

export default  React.memo(({ children }) => (<Container>{children}</Container>))

const Container = s.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(18em, 18em));
gap: 10px;
position: relative;
margin-bottom: 24px;
`;

/* This is better for small screens, once min() is better supported */
/* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
// @media screen and (min-width: 641px) {
// flex-wrap: wrap;
// flex-direction: row;
//}
