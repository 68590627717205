import { useState } from "react";
import { Link } from "react-router-dom";
import s from "@emotion/styled";
import { colorD } from "../style/global";
import { UserMenuSmallIcon, InfoIcon, Logo } from "../icons/icons";
import InfoModal from "./InfoModal";
import UserModal from "./UserModal";
import useStore from "../store/useStore";

const userSelector = (state) => state.user;

export default function Header() {
  const [isInfoModal, setInfoModal] = useState(false);
  const [isUserModal, setUserModal] = useState(false);
  const user = useStore(userSelector);
  return (
    <HeaderContainer>
      <Link to="/">
        <Logo />
      </Link>

      <InfoIconWrapper onClick={() => setInfoModal(!isInfoModal)}>
        <InfoIcon />
      </InfoIconWrapper>
      <UserIconWrapper onClick={() => setUserModal(!isUserModal)}>
        {user.gp.imageUrl ? (
          <GoogleUserImg imageUrl={user.gp.imageUrl} />
        ) : (
          <UserMenuSmallIcon />
        )}
      </UserIconWrapper>
      <InfoModal isActive={isInfoModal} setIsActive={setInfoModal} />
      <UserModal isActive={isUserModal} setIsActive={setUserModal} />
    </HeaderContainer>
  );
}

const HeaderContainer = s.header`
display: flex;
justify-content: space-between;
align-items: center;
padding: 13px 32px 12px 32px;
@media screen and (max-width: 640px) {
padding: 13px 24px 12px 24px;
}
`;
const InfoIconWrapper = s.div`
${(props) =>
  !props.isAuthenticated
    ? "right: 88px; top: 16px;"
    : "bottom: 6px; right: 6px;"};
z-index: 8;
height: 28px;
width: 28px;
border-radius: 50%;
position: absolute;
cursor: pointer;
border: 1.5px solid ${colorD.darkPrim20};
transition: all 0.33s ease-out;
&:hover {
border: 1.5px solid ${colorD.accent100};
transition: all 0.33s ease-out;
}
> svg {
position: absolute;
left: 50%;
top: 5px;
transform: translateX(-50%);
}
`;
const UserIconWrapper = s.div`
cursor: pointer;
&:hover {
border-radius: 12px;
transition: background 0.43s ease;
}
`;
const GoogleUserImg = s.div`
background-image: url(${(props) => props.imageUrl});
background-size: contain;
width: 32px;
height: 32px;
border-radius: 100%;
`;
