import React, { useState } from "react";
import s from "@emotion/styled";
import useGapi from "../utils/hooks/useGapi";
import { colorD, breakpoint } from "../style/global";
import ContentTitle from '../features/fileSystem/ContentTitle';

import useStore from "../store/useStore";

export default React.memo(() => {
  const { client } = useGapi({
    apiKey: "AIzaSyDY_2CY1O06FqR7z879uw7dMBhdWSwnsrY",
    clientId: "114587562380-eqvrt6di696up03i9cu39vodn17l69sr.apps.googleusercontent.com",
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  })

  const { setUploadPreview } = useStore();

  const driveApiCall = async () => {
    await client.drive.files.list({
      'pageSize': 15,
      'fields': "nextPageToken, files(id, name)"
    }).then(function(response) {
      let files = response.result.files;
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          const f = window.gapi.client.drive.files.get({fileId: file.id, alt: 'media'}).then(async (res) => {
            const data = res.body;
            const len = data.length;
            const ar = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              ar[i] = data.charCodeAt(i);
            }
            const b = new File([ar], file.name, { ...res.headers, type: res.headers["Content-Type"]});
            setUploadPreview(b)
          });
        }
      } else {
        console.log('No files found.');
      }
    });
  }

  return (<Container>
            <ContentTitle title='Google Drive backup'/>
             <button onClick={() => driveApiCall() }>
               init
             </button>
           </Container>)
});

const Container = s.div`
transition: all 1s ease;
display: flex;
flex-direction: column;
flex: 1;
padding: 0 32px;
align-items: flex-start;
`
