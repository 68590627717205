import { v4 as uuidv4 } from "uuid";

const user = {
  id: null,
  userId: null,
  sia: {},
  gp: {},
};

const createUserSlice = (set, get) => ({
  user,
  isAuth: false,
  authenticate: (bool) => set({ isAuth: bool }),
  genUserId: () => {
    const id = uuidv4();
    set((state) => ({ user: { ...state.user, id } }));
  },
  getUserGoogleData: (data) =>
    set((state) => ({ user: { ...state.user, gp: data } })),
});

export default createUserSlice;
