export function formatSizeUnits(bytes){
    if      (bytes>=1073741824) {bytes=(bytes/1073741824).toFixed(2)+' GB';}
    else if (bytes>=1048576)    {bytes=(bytes/1048576).toFixed(2)+' MB';}
    else if (bytes>=1024)       {bytes=(bytes/1024).toFixed(2)+' KB';}
    else if (bytes>1)           {bytes=bytes+' bytes';}
    else if (bytes===1)          {bytes=bytes+' byte';}
    else                        {bytes='0 byte';}
    return bytes;
}

export function getDateFromTimeStamp(ts) {
    // will return full date with seconds from timestamp
    var d = new Date(ts);
    return `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()} ${d.getHours()}:${(d.getMinutes() < 10) ? ("0" + d.getMinutes()) : (d.getMinutes())}`
}

export function getCurrentTimeStamp() {
    // will return current timestamp in miliseconds
    const timeStamp = +new Date()
    return  timeStamp;
}

export function middleEllipsis(str) {
  if (str?.length > 20) {
    return str.substr(0, 10) + '...' + str.substr(str.length-10, str.length);
  }
  return str;
}
