import React from "react";
import s from "@emotion/styled";
import { NavLink, Link } from "react-router-dom";
import { colorD } from "../../style/global";

import useStore from "../../store/useStore";

const currentDirSelector = (state) => state.fs.currentDir;
const foldersSelector = (state) => state.fs.folders;
const dirContentsSelector = (state) => state.fs.dirContents;

export default React.memo(() => {
  const currentDir = useStore(currentDirSelector);
  const folders = useStore(foldersSelector);
  const dirContents = useStore(dirContentsSelector);
  const parents = [];
  let parent = currentDir;
  let parentName = "";
  while (parent) {
    parent = Object.entries(dirContents)
      .filter(([k, v]) => v.includes(parent))
      .map(([k, v]) => k)[0];
    parentName = Object.entries(folders)
      .filter(([k, v]) => v.id === parent)
      .map(([k, v]) => v.name)[0];
    if (parent) {
      parents.unshift([parent, parentName]);
    }
  }

  const linkStyl = {
    color: `${colorD.accent100}`,
  };

  return (
    <BreadCrumbs>
      {parents.length ? (
        parents.map((p, i) => (
          <NavLink
            activeStyle={linkStyl}
            exact
            to={`${p[0] === "root" ? "/" : `/folder/${p[0]}`}`}
            key={p}
          >
            <Back>{`${p[0] === "root" ? "Private space" : `/ ${p[1]}`}`}</Back>
          </NavLink>
        ))
      ) : (
        <Link to={`/`}>
          <Back>Private space</Back>
        </Link>
      )}
    </BreadCrumbs>
  );
});

const BreadCrumbs = s.div`
display: flex;
margin-bottom: 18px;
flex: 0 100%;
> a {
color: ${colorD.darkPrim40};
font-size: 15px;
text-decoration: none;
underline: none;
}
`;

const Back = s.span`
max-height: 24px;
margin-right: 4px;
cursor: pointer;
> svg {
margin-right: 8px;
}
`;
