import React, { useEffect } from "react";
import s from "@emotion/styled";
import { colorD, breakpoint } from "../../style/global";
import { CopyIcon } from "../../icons/icons";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useHistory } from "react-router-dom";

import useStore from "../../store/useStore";

// TODO refactor!!!
export default React.memo(({ isActive, setIsActive, setSnackbar }) => {
  const size = useWindowSize();
  const { genSeed, user, authenticate } = useStore();
  let history = useHistory();

  const handleReg = async (e) => {
    // TODO rebuild with useStore
    // TODO handle errors
    e.preventDefault();
    setIsActive(false);
    authenticate(true)
    history.push(`/`);

    //const userDataObj = { seed: user.sia.seed, userId: uuid };
    //setUserData(userDataObj);
    //setNewSeed("");
    //console.log("noeww", newSeed.privateKey);
    // await saveToDBWithoutEncryption(
    //   {
    //     files: {},
    //     directories: {},
    //     dirContents: { root: [] },
    //     r: {},
    //     user: { userId: uuid },
    //   },
    //   newSeed.privateKey
    //);

    //newSeed.privateKey && setIsAuthenticated(true);
    //setFirstAuth(true);
  };

  const generateSeed = () => {
    const newSeed = genSeed();
    handleCopySeed(newSeed)
  }

  const handleCopySeed = async (newSeed) => {
    if (!navigator.clipboard) {
      // Clipboard API is not available
      setSnackbar({ icon: null, text: "Clipboard API is not available", active: true });
      return;
    }
    try {
      await navigator.clipboard.writeText(user.sia.seed);
      setSnackbar({ icon: null, text: "Seed has been copied, save it to the secure place!", active: true });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container isActive={isActive}>
      <Content>
        <Title>
          Copy <span>&</span> save seed
        </Title>
        <SubTitle>SECRET PHRASE</SubTitle>
        <ContentWrapper>
          <ContentLeft>
            <SeedField>{user.sia.seed}</SeedField>
            <CopySeed onClick={handleCopySeed}>
              <IconWrapper>
                <CopyIcon />
              </IconWrapper>
              Copy seed
            </CopySeed>
            <BtnGroup>
              <GrayBtn onClick={() => generateSeed()}>Generate new</GrayBtn>
              <GrayBtn disabled={true} style={{cursor: 'not-allowed'}}>Send to...</GrayBtn>
            </BtnGroup>
          </ContentLeft>
          {breakpoint.mds <= size.width && (
            <ContentRight>
              <span>Copy this phrase and use it every time you log in.</span>
              <span>
                You can go with this phrase or edit to create your own seed.
              </span>
              <span style={{ marginBottom: "50px" }}>
                Don’t tell enyone your seed, this is a secret key to all data in
                your storage.
              </span>
              <CheckBoxGroup>
                <input type="checkbox" />
                <label>(Optional) Backup my seed</label>
              </CheckBoxGroup>
              <div>
                We dont save your seed, which means we don't have access to your files, it's trully secure.
                However, you may want to store seed backup in the dropmyfile.com system, in case you
                lose the seed, then you will need to contact us throgh support team to restore it.
              </div>
            </ContentRight>
          )}
        </ContentWrapper>
        <GrayBtn onClick={handleReg} style={{marginLeft: 'auto', fontSize: '16px', marginTop: '1em'}}>I copied seed, continue</GrayBtn>
      </Content>
    </Container>
  );
});

const CopySeed = s.button`
border: none;
display: flex;
cursor: pointer;
align-items: center;
justify-content: center;
font-weight: 500;
color: ${colorD.lightPrim0};
background: ${colorD.accent100};
width: 100%;
height: 42px;
align-self: flex-end;
border-radius: 16px;
margin-bottom: 15px;
`;

// const RegForm = s.form`
// margin-top: 50px;
// display: flex;
// flex-wrap: wrap;
// > label {
// }
// `;
// const LoginForm = s.form`
// margin: 12px;
// `;

const CheckBoxGroup = s.div`
display: flex;
align-items: center;
> label {
color: ${colorD.darkPrim90};
font-size: 15px;
}
> input {
margin-right: 8px;
}
margin-bottom: 8px;
`;
const DoneBtn = s.div`
width: 100%;
text-align: end;
margin-left: auto;
color: ${colorD.darkPrim80};
font-weight: 500;
font-size: 15px;
cursor: pointer;
`;
const BtnGroup = s.div`
width: 100%;
display: flex;
margin-bottom: 50px;
`;
const GrayBtn = s.button`
border: none;
cursor: pointer;
flex: 1;
display: flex;
justify-content: center;
align-items: center;
background: ${colorD.darkPrim20};
padding: 16px 24px;
border-radius: 17px;
color: ${colorD.darkPrim90};
&:first-of-type {
margin-right: 12px;
}
`;

const SeedField = s.div`
background: ${colorD.darkPrim20};
color: ${colorD.darkPrim90};
padding: 16px;
border-radius: 16px;
margin-bottom: 30px;
word-break: break-all;
line-height: 146%;
`;

const IconWrapper = s.div`
display: flex;
align-items: center;
margin-right: 15px;
`;
const ContentWrapper = s.div`
display: flex;
`;
const ContentLeft = s.div`
display: flex;
flex: 1;
flex-direction: column;

@media screen and (min-width: ${breakpoint.mds}px) {
margin-right: 50px;
}
`;
const ContentRight = s.div`
flex: 1;
display: flex;
flex-direction: column;
> span {
font-size: 14px;
max-width: 256px;
color: ${colorD.darkPrim80};
line-height: 140%;
letter-spacing: .014em;
&:nth-of-type(2) {
margin-bottom: 10px;
}
}
> div {
max-width: 256px;
color: ${colorD.darkPrim60};
letter-spacing: .014em;
line-height: 140%;
font-size: 14px;
}
`;
const Title = s.div`
letter-spacing: .014em;
font-size: 17px;
font-weight: 500;
letter-spacing: 0.014em;
color: ${colorD.lightPrim0};
margin-bottom: 38px;
> span {
color: ${colorD.darkPrim80};
}
`;
const SubTitle = s.div`
letter-spacing: 1px;
font-size: 11px;
font-weight: 500;
color: ${colorD.darkPrim60};
margin-bottom: 12px;
`;
const Content = s.div`
padding: 20px 24px;
`;
const Container = s.div`
z-index: 5;
overflow: hidden;
color: ${colorD.lightPrim0};
display: flex;
flex-direction: column;
position: absolute;
background: ${colorD.darkPrim0};
border-radius: 24px;
top: 120px;
margin: 0 auto;
left: 0;
right: 0;
box-shadow: -2px 20px 22px 8px rgba(14,17,14, .18);
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transform: ${(props) =>
  props.isActive ? "translateY(100px)" : "translateY(0px)"};
transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
width: 652px;
top: 48px;

@media screen and (max-width: ${breakpoint.md}px) {
width: 352px;
top: 48px;
}
@media screen and (max-width: ${breakpoint.sm}px) {
width: 338px;
top: 50px;
}
`;
