import { v4 as uuidv4 } from "uuid";
import { getCurrentTimeStamp } from "./utils";

import { genKeyPairFromSeed, parseSkylink, SkynetClient } from "skynet-js";

const client = new SkynetClient("https://siasky.net");
const skyDBKey = "352S324133kyDBkeyD5G893uoeee23oe24p2uie1testk2ey";
const seed = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData")).seed
  : null;

export const downloadFile = (item) => {
  try {
    const l = item.skylink ? item.skylink : item;
    client.downloadFile(l);
  } catch (error) {
    console.warn(error);
  }
};

export const openFile = (item) => {
  try {
    const l = item.skylink ? item.skylink : item;
    client.openFile(l);
  } catch (error) {
    console.warn(error);
  }
};

export const uploadFile = (f, onFileStateChange) => {
  return f.map(({ file, status }) => {
    const id = uuidv4();
    const ac = new AbortController();
    const fileObj = {
      id,
      lastModified: getCurrentTimeStamp(),
      deviceModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,
      fsType: "file",
      isPrivate: false,
      webkitRelativePath: file.webkitRelativePath,
    };

    const onUploadProgress = (progress) => {
      console.log("progress skynet.js", progress);
      const status = progress === 1 ? "processing" : "uploading";
      onFileStateChange(file, { status, progress });
    };
    const uploaded =
      status === "ready"
        ? client.uploadFile(file, { onUploadProgress }, ac.signal)
        : null;
    return {
      fileObj,
      cancel: ac.abort,
      completed:
        !!uploaded &&
        uploaded
          .then(({ skylink }) => {
            console.log("subst", skylink);
            onFileStateChange(file, { status: "done" });
            return {
              ...fileObj,
              lastModified: getCurrentTimeStamp(),
              skylink: skylink.substr(6),
            };
          })
          .catch((err) => {
            if (err.name === "AbortError") return null;
            throw err;
          }),
    };
  });
};

export const getEntry = (pkey, dbkey) => {
  const { publicKey } = genKeyPairFromSeed(seed);
  try {
    const url = client.registry.getEntryUrl(publicKey, skyDBKey);
    return url;
  } catch (error) {
    console.log(error);
  }
};

export const isValidSkylink = (skylink) => {
  try {
    const o = parseSkylink(skylink);
    console.log("try", o);
    return o;
  } catch (error) {
    console.log("try err", error);
    return false;
  }
};

// TODO refactor
export const getUserStorage = async (sd) => {
  try {
    if (seed) {
      const { publicKey } = genKeyPairFromSeed(seed);
      const { data } = await client.db.getJSON(publicKey, skyDBKey);
      return data;
      //return JSON.parse(data);
    } else {
      const { publicKey } = genKeyPairFromSeed(sd);
      const { data } = await client.db.getJSON(publicKey, skyDBKey);
      return data;
      //return JSON.parse(data);
    }
  } catch (error) {
    console.warn(error);
    return {
      files: {},
      directories: {},
      dirContents: { root: [] },
      r: {},
      user: {},
    };
  }
};

export const saveToDBWithoutEncryption = async (flS, priv) => {
  try {
    if (seed) {
      const { privateKey } = genKeyPairFromSeed(seed);
      //const jsonData = JSON.stringify(flS);
      console.log("seed defined jsonadata: ", flS);
      await client.db.setJSON(privateKey, skyDBKey, flS);
      console.log("done !!! ");
      return flS;
    } else {
      //const jsonData = JSON.stringify(flS);
      //console.log("jsonadata: ", typeof jsonData);
      await client.db.setJSON(priv, skyDBKey, flS);
      return flS;
    }
  } catch (error) {
    console.warn(error);
  }
};
