import React, { useState } from "react";
import s from "@emotion/styled";
//import { useGoogleLogin } from "react-use-googlelogin";
import { colorD, breakpoint } from "../../style/global";
import useGapi from '../../utils/hooks/useGapi';
import { useHistory, useLocation } from 'react-router-dom';

import useStore from "../../store/useStore";
//const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

export default React.memo(
  ({ setRegPage, regPage }) => {

    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const { signIn } = useGapi({
      apiKey: "AIzaSyDY_2CY1O06FqR7z879uw7dMBhdWSwnsrY",
      clientId: "114587562380-eqvrt6di696up03i9cu39vodn17l69sr.apps.googleusercontent.com",
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
    })
    const [passValue, setPassValue] = useState("");

    const { authenticate, getUserGoogleData, getKeyPairFromSeed } = useStore();

    // TODO
    const handleRegSwitch = () => {
      console.log('regPage', regPage)
      setRegPage(!regPage);
    };

    const handleSeedLogin = async (e) => {
      e.preventDefault();
      console.log("continue btn Clicked", passValue);
      setPassValue("");
    };

    const handleGoogleSignIn = async () => {
      // TODO handle error
      const googleUser = await signIn();
      console.log('googleUser', googleUser)
      try {
        if (googleUser) {
          authenticate(!!googleUser.profile.email);
          history.replace(from);
          getUserGoogleData(googleUser.profile);
          // TODO make seed more sophisticated
          getKeyPairFromSeed(googleUser.profile && `${googleUser.googleId}+${googleUser.email}`);
        }
      } catch (err) {
        console.warn('Not authenticated: ', err)
      }
    };
    const handlePassInputChange = (e) => {
      setPassValue(e.target.value);
    };

    //const [showPage, setShowPage] = useState(false);
    // //seed login
    //   const data = await getUserStorage(passValue);
    //   if (data.user.userId && passValue) {
    //     const userDataObj = { seed: passValue, userId: data.user.userId };
    //     setUserData(userDataObj);
    //     setIsAuthenticated(true);
    //   }
    //const user = useStore((state) => state.user);
    // const populateUserGoogleData = useStore(
    //   (state) => state.populateUserGoogleData
    // );
    // const genSeed = useStore((state) => state.genSeed);
    // const genId = useStore((state) => state.genId);
    //const authenticate = useStore((state) => state.authenticate);
    // useEffect(() => {
    //   if (passValue) {
    //     setShowPage(true);
    //   } else {
    //     setTimeout(() => {
    //       setShowPage(true);
    //     }, 100);
    //   }
    // }, []);
    // const handleShowPass = e => {
    //     e.preventDefault()
    //     setShowPass(!showPass)
    // }
    // const responseGoogle = (resp) => {
    //   populateUserGoogleData(resp.profileObj);
    //   authenticate(true);
    //   console.log("user.id etc", user.id === null && !user.sia.seed);
    //   if (user.id === null && !user.sia.seed) {
    //     console.log("true !!!");
    //     genSeed();
    //     genId();
    //   }
    // };

    return (
      <Container isActive={true}>
        <Wrapper isActive={true}>
          <Title>Log in</Title>
          <Form>
            <FormTitle>{true ? "YOUR SEED" : "YOUR EMAIL"}</FormTitle>
            <Input
              onChange={handlePassInputChange}
              value={passValue}
              type={true ? "password" : "email"}
              placeholder={true ? "Seed..." : "Email..."}
            />
            <ContinueBtn onClick={(e) => handleSeedLogin(e)}>
              Continue
            </ContinueBtn>
          </Form>
          {/* <span>or</span> */}
          {/* <button onClick={handleGoogleSignIn}>G sign in</button> */}
          <SubTitle>
            First time using dropmyflie.com?{" "}
            <span onClick={() => handleRegSwitch()}>Create an account</span>
          </SubTitle>
        </Wrapper>
      </Container>
    );
  }
);

//background: #1F202C;
const Container = s.div`
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
background: #1F202C;
align-items: center;
z-index: 4;
border-bottom-left-radius: 72px;
border-top-left-radius: 72px;
font-size: 14px;
font-weight: 500;
color: ${colorD.lightPrim0};
flex: 0 ${(props) => (props.isActive ? "50%" : "0")};
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transition: all 0.4s ease;
@media screen and (max-width: ${breakpoint.md}px) {
flex: 0 ${(props) => (props.isActive ? "100%" : "0")};
}
`;
const Wrapper = s.div`
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transition: all 0.4s ease;
`;
const Title = s.div`
letter-spacing: .014em;
font-size: 26px;
font-weight: 500;
color: ${colorD.lightPrim0};
margin-bottom: 5px;
`;
const SubTitle = s.div`
margin-top: 2em;

font-size: 16px;
font-weight: 400;
color: ${colorD.darkPrim80};
> span {
color: ${colorD.accent100};
margin-left: 4px;
cursor: pointer;
}
margin-bottom: 32px;
`;
const Form = s.form`
display: flex;
flex-direction: column;
`;
const FormTitle = s.label`
margin-top: 2em;
font-size: 11px;
font-weight: 500;
color: ${colorD.darkPrim60};
margin-bottom: 12px;
`;
const Input = s.input`
border-radius: 16px;
background: ${colorD.darkPrim20};
height: 46px;
border: none;
text-indent: 16px;
color: ${colorD.lightPrim0};
&::placeholder {
color: ${colorD.lightPrim80};
}
margin-bottom: 24px;
`;
const ContinueBtn = s.button`
cursor: pointer;
border: none;
font-weight: 500;
color: ${colorD.lightPrim0};
background: ${colorD.accent100};
width: 115px;
height: 42px;
align-self: flex-end;
border-radius: 16px;
`;
