import React from 'react'
import s from "@emotion/styled";
import { colorD } from "../../style/global";
import { ApproveIcon, DotIcon, SecureDirectoryIcon } from "../../icons/icons";

import RenameForm from '../../reusable/fs/RenameForm'
import Name from '../../reusable/fs/Name'
import Date from '../../reusable/fs/Date'

export default  React.memo(({
  fs,
  folder,
  index,
  renameFolderRef,
  showRename,
  renameItemId,
  handleRenameFolderOnSubmit,
  handleFolderNameChange,
  children,
}) => {

  const childFilesFilteredList = (x) =>
        fs.dirContents[x.id].filter((y) => [...Object.keys(fs.folders)].includes(y))
          .length;

  return (
    <Container>
      <FolderUpper>
        <FolderIconWrapper>
          <SecureDirectoryIcon />
        </FolderIconWrapper>
        {showRename && renameItemId === folder.id && (
          <StorageItemRenAppr
            onClick={(e) => handleRenameFolderOnSubmit(e, index)}
          >
            <ApproveIcon />
          </StorageItemRenAppr>
        )}
      </FolderUpper>
      <StorageItemInfo>
        {showRename && renameItemId === folder.id ? (
          <RenameForm
            name={folder.name}
            index={index}
            handleNameChange={handleFolderNameChange}
            handleRenameOnSubmit={handleRenameFolderOnSubmit}
            ref={renameFolderRef.current[index]}
          />
        ) : (
          <Name name={folder.name}/>
        )}
        <StorageItemInfoBottom>
          <Date date={folder.lastModified}/>
          <DotIcon />
          <FolderFilesQuantity>
            {!!fs.dirContents[folder.id] && childFilesFilteredList(folder)}{" "}
            {!!fs.dirContents[folder.id] && childFilesFilteredList(folder) === 1 ? "folder" : "folders"}
          </FolderFilesQuantity>
          <DotIcon />
          <FolderFilesQuantity>
            {!!fs.dirContents[folder.id] && fs.dirContents[folder.id].length - childFilesFilteredList(folder)}{" "}
            {!!fs.dirContents[folder.id] && fs.dirContents[folder.id].length - childFilesFilteredList(folder) === 1 ? "file" : "files"}
          </FolderFilesQuantity>
        </StorageItemInfoBottom>
      </StorageItemInfo>
    </Container>
  );
})

const Container = s.div`
display: flex;
flex-direction: column;
`;

const StorageItemInfo = s.div`
display: flex;
margin-right: auto;
flex-direction: column;
@media screen and (max-width: 375px) {
margin-left: 0px;
}
`;
const StorageItemInfoBottom = s.div`
display: flex;
color: ${colorD.darkPrim80};
font-size: 13px;
justify-content: space-between;
`;
const StorageItemRenAppr = s.div`
cursor: pointer;
height: 24px;
padding: 8px;
border-radius: 12px;
margin-left: 12px;
transition: all 0.43s ease-out;
&:hover {
transition: all 0.43s ease-out;
background: ${colorD.darkPrim40};
path {
transition: all 0.43s ease-out;
stroke: ${colorD.lightPrim0};
}
}
`;
const FolderUpper = s.div`
margin-bottom: 28px;
display: flex;
justify-content: space-between;
align-items: center;
`;
const FolderIconWrapper = s.div`
 height: inherit;
 align-items: flex-start;
`;
const FolderFilesQuantity = s.div`
color: ${colorD.darkPrim80};
font-size: 13px;
`;
