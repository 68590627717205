import s from "@emotion/styled";
import Border from "../reusable/border";
import { colorD } from "../style/global";

export default function Subtitle({ title, children }) {
  return (
    <>
      <Title>
        {children}
        <span>{title}</span>
      </Title>
      <Border />
    </>
  );
}

const Title = s.div`
display: flex;
align-items: center;
margin: 20px 0 22px 0;
> span {
font-size: 16px;
font-weight: 700;
color: ${colorD.darkPrim40};
padding-left: 16px;
}
@media screen and (max-width: 375px) {
}
`;
