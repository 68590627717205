import React, { useState } from "react";
import s from "@emotion/styled";
import { BWLogo, ImgCred } from "../../icons/icons";
import { colorD, breakpoint } from "../../style/global";
import useWindowSize from "../../utils/hooks/useWindowSize";
import SideLogin from "./SideLogin";
import RegRightSide from "./SideRegistration";
import SeedRegModal from "./SeedRegModal";

import bgimg from "../../icons/loginBg.jpg";

export default React.memo(({ setSnackbar }) => {
  const [regPage, setRegPage] = useState(false);
  const [regModal, setRegModal] = useState(false);
  const size = useWindowSize();

  return (
    <Container>
      {breakpoint.lg <= size.width && (
        <>
          <BgImg />
          <ImgCredWr>
            <ImgCred />
          </ImgCredWr>
          <LeftCont>
            <LeftContWrap>
              <LogoWrapper>
                <BWLogo />
              </LogoWrapper>
              <TitleWrapper>
                <LoginTitle>
                  <span>Backup your G-Drive & Dropbox.</span>
                  <span>Share and store files securely without limits.</span>
                </LoginTitle>
              </TitleWrapper>
            </LeftContWrap>
          </LeftCont>
        </>
      )}
      {regPage ? (
        <RegRightSide
          setRegPage={setRegPage}
          regPage={regPage}
          setRegModal={setRegModal}
        />
      ) : <SideLogin setRegPage={setRegPage} regPage={regPage} />}
      {regModal && <Dimmer />}
      <SeedRegModal
        isActive={regModal}
        setIsActive={setRegModal}
        setSnackbar={setSnackbar}
      />
    </Container>
  );
});

const ImgCredWr = s.div`
position: absolute;
z-index: 2;
left: 32px;
bottom: 28px;
`;
const LoginTitle = s.div`
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: ${colorD.lightPrim0};
display: flex;
flex-wrap: wrap;
max-width: 428px;
`;

const BgImg = s.div`
background-image: url(${bgimg});
background-size: cover;
filter: brightness(75%);
width: 59vw;
height: 100vh;
position: fixed;
z-index: 0;
`;
const Dimmer = s.div`
width: 100vw;
height: 100vh;
position: absolute;
opacity: .7;
z-index: 4;
background: #171720;
`;
const Container = s.div`
position: relative;
height: 100vh;
width: 100wh;
display: flex;
background: #1F202C;
overflow: hidden;
`;
const LeftContWrap = s.div`
position: absolute;
left: 268px;
top: 200px;
`;
const LeftCont = s.div`
position: relative;
z-index: 1;
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;
const LogoWrapper = s.div`
margin-bottom: 48px;
`;
const TitleWrapper = s.div`
margin-bottom: 8px;
`;
const LoginWrap = s.div`
display: flex;
align-items: center;
margin-top: 32px;
`;
