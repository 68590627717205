import React, { useState } from "react";
import s from "@emotion/styled";
import { breakpoint, colorD } from "../../style/global";
import Search from "../../components/Search";
import Trademark from "../../components/Trademark";
import Nav from "./Nav";
import useWindowSize from "../../utils/hooks/useWindowSize";

export default function Sidebar() {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const size = useWindowSize();
  return (
    <Container>
      <Search
        isSidebarActive={isSidebarActive}
        setIsSidebarActive={setIsSidebarActive}
      />
      <Nav />
      {size.width >= breakpoint.md && <Trademark />}
    </Container>
  );
}

const Container = s.div`
max-width: 260px;
display: flex;
flex-direction: column;
transition: height 1s ease;
flex: 0 260px;
min-width: 260px;
overflow: hidden;
border-right: 1px solid ${colorD.darkPrim10};
position: relative;
transition: height 0.8s ease, opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
`;

// width={size.width}
// isActive={isSidebarActive}
// breakpoint={breakpoint}
// @media screen and (max-width: 640px) {
//  border-right: 0px solid ${colorD.darkPrim10};
//  transition: height 0.5s ease, opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
//  height: ${(props) =>
//    props.width <= props.breakpoint.md && props.isActive ? "auto" : "0px"};
//  flex: ${(props) =>
//    props.width <= props.breakpoint.md && props.isActive ? "0 260px" : "0"};
//  visibility: ${(props) =>
//    props.width <= props.breakpoint.md && props.isActive ? "visible" : "hidden"};
//  transform: ${(props) =>
//    props.width <= props.breakpoint.md && props.isActive
//      ? "translateY(0px)"
//      : "translateY(-20px)"};
//  opacity: ${(props) =>
//    props.width <= props.breakpoint.md && props.isActive ? "1" : "0"};
// }
