import React from "react";
import s from "@emotion/styled";
import { breakpoint, colorD } from "../style/global";
import useWindowSize from "../utils/hooks/useWindowSize";
import { SearchIcon, MoreIcon, CancelIcon } from "../icons/icons";

import useStore from "../store/useStore";

const searchInputSelector = (state) => state.searchInput;

export default React.memo(({ isSidebarActive, setIsSidebarActive }) => {
  const searchInput = useStore(searchInputSelector);
  const { setSearchList, setSearchInput } = useStore();

  // TODO think about https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
  // for better performance
  const handleChange = (event) => {
    setSearchInput(event.target.value);
    setSearchList();
  };

  const size = useWindowSize();
  return (
    <Container>
      <SearchWrapper>
        <SearchIcon />
        <Input
          type="search"
          placeholder="Search"
          value={searchInput}
          onChange={handleChange}
        />
        {size.width <= breakpoint && (
          <MenuBtn onClick={() => setIsSidebarActive(!isSidebarActive)}>
            <span>{isSidebarActive ? <CancelIcon /> : <MoreIcon />}</span>
          </MenuBtn>
        )}
      </SearchWrapper>
    </Container>
  );
});

const MenuBtn = s.div`
display: flex;
flex: 0;
color: ${colorD.lightPrim0};
font-weight: bold;
font: size;
z-index: 2;
> span {
diplay: flex;
align-items: center;
padding: 14px 12px 10px 12px;
}
`;
const Input = s.input`
text-indent: 32px;
width: inherit;
flex: 1;
background: #242533;
border: none;
border-radius: 5px;
padding: 8px;
width: 100%;
color: ${colorD.lightPrim0};
border-radius: 40px;
`;

const SearchWrapper = s.div`
padding: 12px 12px 6px 12px;
display: flex;
align-items: center;
position: relative;
flex-wrap: wrap;
> svg {
position: absolute;
left: 20px;
top: 18px;
}
`;
const Container = s.div`
justify-content: space-around;
padding: 4px 12px;
`;

// @media screen and (max-width: 640px) {
// left: 34px;
// top: 27px;
// }
// @media screen and (max-width: 640px) {
// padding: 0;
// }

// @media screen and (max-width: 640px) {
// margin-left: 12px;
// }
//{searchInput && !resultsList.length && <NoResults></NoResults>}
// const NoResults = s.div`
// padding-top: 6px;
// flex: 0 100%;
// font-size: 14px;
// color: ${colorD.darkPrim80};
// min-height: 24px;
// text-align: center;
// margin: 0 12px;
// cursor: pointer;
// height: 24px;
// display: flex;
// justiy-content: space-between;
// align-items: flex-end;
// > span {
// margin-right: auto;
// min-height:24px;
// }
// align-items: center;
// `;

//const fsSelector = (state) => state.fs;
//const fs = useStore(fsSelector);
// useEffect(() => {
//   const results = !searchInput
//     ? []
//     :
// }, [searchInput]);
// const files = [...Object.values(fs.files), ...Object.values(fs.folders)];
// files.filter((file) =>
//   file.name.toLowerCase().includes(searchInput.toLocaleLowerCase())
// );
