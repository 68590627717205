import React from "react";
import s from "@emotion/styled";
import { colorD } from "../../style/global";

export default React.forwardRef((props, ref) => (
  <FormRename onSubmit={(e) => props.handleRenameOnSubmit(e, props.index)}>
    <InputRename
      type="text"
      defaultValue={props.name}
      autoFocus
      ref={ref}
      onChange={(e) => props.handleNameChange(e, props.index)}
    />
  </FormRename>
));


const FormRename = s.form`
margin-bottom: 8px;
display: flex;
`;
const InputRename = s.input`
background: ${colorD.darkPrim20};
border: none;
flex: 1;
border-radius: 4px;
text-indent: 4px;
color: ${colorD.lightPrim0};
`;
