import s from "@emotion/styled";
import { colorD } from "../style/global";

export default function RoundBtn({ isValidLink, children }) {
  console.log("islinkvalid", isValidLink);
  return <RoundButton isValidLink={isValidLink}>{children}</RoundButton>;
}

const RoundButton = s.div`
cursor: ${(props) => (props.isValidLink ? `pointer` : `not-allowed`)};
display: flex;
align-items: center;
justify-content: center;
outline: none;
text-decoration: none;
border: none;
border-radius: 100%;
height: 96px;
width: 96px;
font-weight: 600;
font-size: 15px;
transition: all 0.38s ease-out;
${(props) =>
  props.isValidLink
    ? `background: ${colorD.accent100};`
    : `background: ${colorD.darkPrim20};`}

svg, path {
stroke: ${(props) => (props.isValidLink ? `#F6F6FA` : `#83849D`)};
}
`;

//: "background: rgb(84,79,245); background: linear-gradient(90deg, rgba(84,79,245,1) 35%, rgba(103,106,246,1) 100%); box-shadow: -2px 20px 22px 8px rgba(14,17,14, .25); "}
// const AddFileText = s.span`
// display: flex;
// justify-content: center;
// align-items: center;
// `;

//<AddFileText></AddFileText>
