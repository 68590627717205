import create from "zustand";
import { persist } from "zustand/middleware";
import { get, set } from "idb-keyval";
import createFileSystemSlice from "./createFileSystemSlice";
import createUserSlice from "./createUserSlice";
import createSearchSlice from "./createSearchSlice";
import createSkynetSlice from "./createSkynetSlice";

export const IDBStorage = {
  getItem: (name) => {
    // Exit early on server
    if (typeof indexedDB === "undefined") {
      return null;
    }
    const value = get(name);

    return value || null;
  },
  setItem: async (name, value) => {
    // Exit early on server
    if (typeof indexedDB === "undefined") {
      return;
    }
    set(name, value);
  },
};

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (args) => {
      console.log("  applying", args);
      set(args);
      console.log("  new state", get());
    },
    get,
    api
  );

const useStore = create(
  log(
    persist(
      (set, get) => ({
        ...createFileSystemSlice(set, get),
        ...createUserSlice(set, get),
        ...createSearchSlice(set, get),
        ...createSkynetSlice(set, get),
      }),
      {
        name: "skydrop-storage",
        // TODO solve indexd db long persisting issue
        //getStorage: () => IDBStorage,
      }
    )
  )
);

export default useStore;
