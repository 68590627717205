import React from 'react'
import s from "@emotion/styled";
import { colorD } from "../../style/global";
import { ApproveIcon, FileIcon } from "../../icons/icons";

import RenameForm from '../../reusable/fs/RenameForm'
import Name from '../../reusable/fs/Name'
import Date from '../../reusable/fs/Date'
import Size from '../../reusable/fs/Size'

export default  React.memo(({
  file,
  index,
  renameFileRef,
  showRename,
  renameItemId,
  handleRenameFileOnSubmit,
  handleFileNameChange,
  children,
}) => {
  return (
    <Container>
      <Icon>
        <FileIcon />
        <span>{file.type && file.type.replace(/.*\//, "").toUpperCase()}</span>
      </Icon>
      <Info>
        {showRename && renameItemId === file.id ? (
          <RenameForm
            name={file.name}
            index={index}
            handleNameChange={handleFileNameChange}
            handleRenameOnSubmit={handleRenameFileOnSubmit}
            ref={el => renameFileRef.current[index] = el}
          />
        ) : (
          <Name name={file.name} />
        )}
        <InfoBottom>
          <Date date={file.lastModified} />
          <Size size={file.size} />
        </InfoBottom>
      </Info>
      {showRename && renameItemId === file.id && (
        <RenameApproveWrapper
          onClick={(e) => handleRenameFileOnSubmit(e, index)}
        >
          <ApproveIcon />
        </RenameApproveWrapper>
      )}
    </Container>
  );
})

//const [stateItem, setStateItem] = useState({});
// const menuClickHandler = (item) => {
//   setStateItem(item[1]);
//   if (isActive && item[1] !== stateItem) setIsActive(true);
//   else setIsActive(!isActive);
//   pickedMenuItem(item);
// };
// {breakpoint.mds >= size.width && (
//   <Dots
//     onClick={() => menuClickHandler(Object.entries(fs.files)[i])}
//   >
//     <MoreHorizontalIcon />
//   </Dots>
// )}
// const Dots = s.div`
// display: flex;
// flex: 0 24px;
// min-height: 40px;
// align-items: center;
// justify-content: center;
// padding: 0 8px;
// height: 24px;
// cursor: pointer;
// &:active {
// background: ${colorD.darkPrim40};
// border-radius: 12px;
// }
// > svg {
// path {
// stroke: #7C7D84;
// }
// }
// `;

const Container = s.div`
display: flex;
cursor: context-menu;
flex: 1;
align-items: center;
justify-content: stretch;
border-radius: 16px;
transition: all 0.43s ease-out;
padding: 24px 16px;

&:hover {
background: ${colorD.darkPrim10};
border-radius: 16px;
transition: all 0.43s ease-out;
span {
transition: color 0.43s ease-out;
color: ${colorD.lightPrim0};
}
}
@media screen and (max-width: 640px) {
padding: 12px 16px;
}
@media screen and (max-width: 375px) {
padding: 12px 16px;
}
`;
// @media screen and (min-width: 641px) {
// max-width: 337px;
// min-width: 337px;
// }


const Icon = s.div`
margin-right: 16px;
display: flex;
align-items: center;
justify-content: center;
color: ${colorD.lightPrim0};
height: 20px;
position: relative;
> span {
font-size: 8px;
position: absolute;
text-align: center;
left: 1px;
right: 0;
bottom: 0;
width: 30px;
overflow: hidden;
}
`;
const Link = s.div`
cursor: pointer;
height: 24px;
padding: 8px;
border-radius: 12px;
margin-left: 12px;
transition: all 0.43s ease-out;
&:active {
background: ${colorD.darkPrim40};
}
&:hover {
color: ${colorD.lightPrim0};
transition: all 0.43s ease-out;
background: ${colorD.darkPrim40};
border-radius: 12px;
path {
stroke: ${colorD.lightPrim0};
}
}
path {
stroke: ${colorD.darkPrim80};
}
@media screen and (max-width: 413px) {
display: none;
}
`;

const Info = s.div`
display: flex;
margin-right: auto;
flex-direction: column;
@media screen and (max-width: 375px) {
margin-left: 0px;
}
`;
const InfoBottom = s.div`
display: flex;
color: ${colorD.darkPrim80};
font-size: 13px;
`;

const RenameApproveWrapper = s.div`
cursor: pointer;
height: 24px;
padding: 8px;
border-radius: 12px;
margin-left: 12px;
transition: all 0.43s ease-out;
&:hover {
transition: all 0.43s ease-out;
background: ${colorD.darkPrim40};
path {
transition: all 0.43s ease-out;
stroke: ${colorD.lightPrim0};
}
}
`;
