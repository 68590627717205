const createSearchSlice = (set, get) => ({
  searchList: [],
  //setSearchList: (items) => set({ searchList: [...items] }),
  setSearchList: (items) =>
    set((state) => ({
      searchList: [
        ...Object.values(state.fs.files),
        ...Object.values(state.fs.folders),
      ].filter((file) =>
        file.name.toLowerCase().includes(get().searchInput.toLocaleLowerCase())
      ),
    })),
  searchInput: "",
  setSearchInput: (input) => set({ searchInput: input }),
});

export default createSearchSlice;
