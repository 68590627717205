import { SkynetClient, genKeyPairAndSeed, genKeyPairFromSeed } from "skynet-js";
import { v4 as uuidv4 } from "uuid";

const client = new SkynetClient("https://siasky.net");
// TODO should skydbkey be id of each user?

const createUserSlice = (set, get) => ({
  genSeed: () => {
    const { privateKey, publicKey, seed } = genKeyPairAndSeed();
    set((state) => ({
      user: { ...state.user, sia: { seed, privateKey, publicKey }, userId: uuidv4() },
    }));
  },
  getKeyPairFromSeed: (seed) => {
    const { privateKey, publicKey } = genKeyPairFromSeed(seed);
    set((state) => ({
      user: { ...state.user, sia: { privateKey, publicKey } },
    }));
  },
  getSkyDB: async (key = "fs") => {
    try {
      const { data } = await client.db.getJSON(get().user.sia.publicKey, key);
      console.log("data", data);
      set((state) => ({ ...state, [key]: data }));
    } catch (err) {
      console.warn(err);
    }
  },
  setSkyDB: async (payload, key = "fs") => {
    console.log("get", get().fs);
    try {
      await client.db.setJSON(get().user.sia.privateKey, key, payload);
      console.log(" db set done!", payload, key);
    } catch (err) {
      console.warn(err);
    }
  },
  onFileUploadStateChange: (fileObj, progress, status) => {
    const index = get().fs.uploadPreviewList.findIndex((f) => f.fileObj === fileObj);
    set((state) => ({
      fs: {
        ...state.fs,
        uploadPreviewList: [
          ...state.fs.uploadPreviewList.map(
            (file, i) => i === index ? Object.assign(file, { ...file, progress, status }) : file
          ),
        ],
      },
    }));

    // const uploadPreview = (previousFiles) => {
    //   console.log("prev file in previewmodal", previousFiles);
    //   const index = previousFiles.findIndex((f) => f.file === file);
    //   const { current, total } = previousFiles.reduce(
    //     ({ current, total }, { progress, file: { size } }) => ({
    //       current: current + progress * size,
    //       total: total + size,
    //     }),
    //     { current: 0, total: 0 }
    //   );
    //   // TODO setTotalProgress 1 tick faster
    //   //console.log("current/total", current / total);
    //   const totalProgress = current / total;
    //   setTotalProgress(totalProgress.toFixed(4) * 100);
    //   return [
    //     ...previousFiles.slice(0, index),
    //     { ...previousFiles[index], ...state },
    //     ...previousFiles.slice(index + 1),
    //   ];
    // };
  },
  skynetFileUpload: (list, onFileStateChange) => {
    return list.map(async ({ fileBlob, fileObj, base64, status }) => {
      const ac = new AbortController();

      const onUploadProgress = (progress) => {
        const s = progress === 1 ? "processing" : "uploading";
        onFileStateChange(fileObj, progress, s)

        const { current, total } = get().fs.uploadPreviewList.reduce(
          ({ current, total }, { progress, fileObj: { size } }) => {
            return ({
              current: current + progress * size,
              total: total + size,
            })},
          { current: 0, total: 0 }
        );
        const totalProgress = current / total * 100;

        get().setTotalProgress(totalProgress)
      };

      if (Object.keys(fileBlob).length === 0) {
        const res = await fetch(base64);
        const blob = await res.blob();
        fileBlob = new File([blob], fileObj.name, fileObj);
      }

      const uploaded = status === "ready" && client.uploadFile(fileBlob, { onUploadProgress }, ac.signal);

      return {
        fileObj,
        cancel: ac.abort,
        completed:
        !!uploaded &&
          uploaded
          .then(({ skylink }) => {
            get().onFileUploadStateChange(fileObj, 1, 'done');
            return {
              ...fileObj,
              skylink: skylink.substr(6),
            };
          })
          .catch((err) => {
            if (err.name === "AbortError") return null;
            throw err;
          }),
      };
    });
  },
});

export default createUserSlice;
