import React from "react";

export default React.memo(
  ({ data, onSelectChange, className, defaultValue }) => {
    const handleChange = (e) => {
      // TODO remove dirty hack 'root' def val
      const payload = e.target.value === "My Uploads" ? "root" : e.target.value;
      onSelectChange(payload);
    };
    const options = data.map((data) => (
      <option key={data.id} value={data.id}>
        {data.name}
      </option>
    ));
    return (
      <select
        name="zSearch"
        className={className ? className : "zSelect"}
        onChange={handleChange}
      >
        {defaultValue && <option>{defaultValue}</option>}
        {options}
      </select>
    );
  }
);
