import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import s from "@emotion/styled";
import { colorD } from "../../style/global";

import Menu from "../../components/ContextMenu";
import ItemsContainer from "../../reusable/fs/ItemsContainer";
import ItemsCounterTitle from "../../reusable/fs/ItemsCounterTitle";
import File from "./File";
import Folder from "./Folder";
import usePrevious from '../../utils/hooks/usePrevious';

import useStore from "../../store/useStore";

const fileSystemSelector = (state) => state.fs;

export default React.memo(({ setSnackbar }) => {
  const { mv, rename, setItemPreview } = useStore();
  const fs = useStore(fileSystemSelector);

  const history = useHistory();

  const itemRef = useRef({});

  const renameItemRef = useRef({});
  const draggingItem = useRef({});
  const dragOverItem = useRef({});
  const dci = useRef({});
  const doi = useRef({});


  const [name, setName] = useState("");
  const [showRename, setShowRename] = useState(false);
  const [renameItemId, setRenItemId] = useState(null);
  const [element, setElement] = useState(null)

  const prevEl = usePrevious(element)
  if (prevEl && prevEl !== element) {
    prevEl.style.outline = '';
  }

  const filesList = fs.currentDirItems.files
  const foldersList = fs.currentDirItems.folders
  const mixedList = fs.currentDirItems.mixed

  const handleDragStart = (e, position, id, type) => {
    console.log('type', type)
    draggingItem.current = position;
    dci.current = id;
    itemRef.current[draggingItem.current].style.opacity = 0.4
  };
  const handleDragEnter = (e, position, id) => {
    dragOverItem.current = position;
    doi.current = id;
  };
  const handleDrop = (e, position, id, type) => {
    e.preventDefault();
    if (fs.dirContents[doi.current] && dci.current !== doi.current) {
      mv(dci.current, doi.current);
      setSnackbar({ icon: null, text: "Item has been moved", active: true });
      if (type === 'folder') {
        itemRef.current[dragOverItem.current].style.border = `2px solid ${colorD.darkPrim10}`
      }
    }
  };
  const handleDragOver = (e, position, id, type) => {
    e.preventDefault();
    if (fs.dirContents[doi.current] && dci.current !== doi.current) {
      if (type === 'folder') {
        itemRef.current[
          dragOverItem.current
        ].style.border = `2px dashed ${colorD.accent100}`;
      }
    }
  };
  const handleDragLeave = (e, position, id, type) => {
    if (type === 'folder') {
      itemRef.current[dragOverItem.current].style.border = `2px solid ${colorD.darkPrim10}`;
    }
  };
  const handleDragEnd = (e, position, id, type) => {
    itemRef.current[draggingItem.current].style.opacity = 1
  };
  const handleRightClick = (e, i, item) => {
    e.preventDefault();
    console.log('context menu right click', item)
    // setTimeout(() => {
    // }, 0)
  }
  const handleRenameItemChange = (e, i) => {
    if (renameItemRef.current[i].contains(e.target)) {
      setName(renameItemRef.current[i].value);
    }
  };
  const handleRenameItemSubmit = (e, i) => {
    e.preventDefault();
    const name = renameItemRef.current[i].value;
    const foundItem = mixedList.find(({id}) => id === renameItemId)
    rename(renameItemId, name, foundItem.fsType);
    setName("");
    setShowRename(false);
    setSnackbar({ icon: null, text: `${foundItem.fsType} has been renamed`, active: true });
  };
  const handleLink = (e, id) => {
    e.preventDefault();
    !showRename && history.push(`/folder/${id}`);
  };
  const handleSelect = (e, i, id) => {
    itemRef.current[i].style.outline = `1px solid ${colorD.accent100}`;
    itemRef.current[i].style.borderRadius = `16px`;
    setElement(itemRef.current[i])
  };
  const handleFileDoubleClick = (e, item) => {
    e.preventDefault();
    setItemPreview(item, true)
  };


  //if (pickedItem?.style) pickedItem.style.border = ''
  return (
    <>
   <Section>
     {!!filesList.length && (
       <ItemsCounterTitle name="Files" items={filesList} />
     )}
     <ItemsContainer>
       {mixedList.map((x, i) => x.fsType === 'file' && (
         <div
           key={x.id}
           ref={el => itemRef.current[i] = el}
           onDragStart={(e) => handleDragStart(e, i, x.id, x.fsType)}
           onDragOver={(e) => e.preventDefault()}
           onDragEnter={(e) => handleDragEnter(e, i, x.id, x.fsType)}
           onDragLeave={(e) => handleDragLeave(e, i, x.id, x.fsType)}
           onDragEnd={(e) => handleDragEnd(e, i, x.id, x.fsType)}
           onDrop={(e) => handleDrop(e, i, x.id, x.fsType)}
           onContextMenu={(e) => handleRightClick(e, i, x)}
           draggable
           onClick={(e) => handleSelect(e, i, x)}
           onDoubleClick={(e) => handleFileDoubleClick(e, x)}
         >
           <File
             file={x}
             index={i}
             renameFileRef={renameItemRef}
             showRename={showRename}
             renameItemId={renameItemId}
             handleRenameFileOnSubmit={handleRenameItemSubmit}
             handleFileNameChange={handleRenameItemChange}
           />
           <Menu
             name={name}
             setShowRename={setShowRename}
             setSnackbar={setSnackbar}
             setRenItemId={setRenItemId}
             outerRef={itemRef.current[i]}
             item={x}
           />
         </div>
       ))}
     </ItemsContainer>
   </Section>
      <Section>
        {!!foldersList.length && (
          <ItemsCounterTitle
            name="Folders"
            items={foldersList}
            style={{ marginTop: "24px" }}
          />
        )}
        <ItemsContainer style={{ marginLeft: "-24px" }}>
          {mixedList.map((x, i) =>
            x.fsType === 'folder' && (
              <FolderWrapper
                key={x.id}
                ref={el => itemRef.current[i] = el}
                onDragStart={(e) => handleDragStart(e, i, x.id, x.fsType)}
                onDragOver={(e) => handleDragOver(e, i, x.id, x.fsType)}
                onDragEnter={(e) => handleDragEnter(e, i, x.id, x.fsType)}
                onDragLeave={(e) => handleDragLeave(e, i, x.id, x.fsType)}
                onDragEnd={(e) => handleDragEnd(e, i, x.id, x.fsType)}
                onDrop={(e) => handleDrop(e, i, x.id, x.fsType)}
                onContextMenu={(e) => handleRightClick(e, i, x)}
                draggable
                onClick={(e) => handleSelect(e, i, x.id)}
                onDoubleClick={(e) => handleLink(e, x.id)}
              >
                <Folder
                  fs={fs}
                  folder={x}
                  index={i}
                  renameFolderRef={renameItemRef}
                  showRename={showRename}
                  renameItemId={renameItemId}
                  handleRenameFolderOnSubmit={handleRenameItemSubmit}
                  handleFolderNameChange={handleRenameItemChange}
                />
                <Menu
                  name={name}
                  setRenItemId={setRenItemId}
                  setShowRename={setShowRename}
                  setSnackbar={setSnackbar}
                  outerRef={itemRef.current[i]}
                  item={x}
                />
              </FolderWrapper>
            )
          )}
        </ItemsContainer>
      </Section>
    </>
  );
});

const Section = s.section`
display: flex;
flex-direction: column;
width: 100%;
`;

const FolderWrapper = s.div`
cursor: context-menu;
border-radius: 20px;
border: 2px solid ${colorD.darkPrim10};
padding: 24px;
transition: all 0.43s ease-out;
&:hover {
background: ${colorD.darkPrim10};
border-radius: 20px;
transition: all 0.43s ease-out;
}
`
