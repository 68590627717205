import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { colorD } from "../../style/global";
import s from "@emotion/styled";
import { CancelIcon } from "../../icons/icons";
import { formatSizeUnits, getDateFromTimeStamp} from '../../utils/utils';

import useStore from "../../store/useStore";

const fsItemPreviewSelector = (state) => state.fs.itemPreview;

export default React.memo(({isPreview, setSnackbar}) => {
  const { item, isOpen } = useStore(fsItemPreviewSelector);
  const { setItemPreview, rm, rename } = useStore();
  const [fetchedData, setFetchedData] = useState()
  const [togglePreview, setTogglePreview] = useState(isOpen)

  const history = useHistory();
  useEffect(async () => {
    //console.log('async fetch useeffect', item.skylink)
    //let response = await fetch(`https://siasky.net/fAZ-fR9vtGZq-MONXzNvgREPdkv_ID3YUeVEWvoyhNtMpQ/`);
    if (item?.skylink) {
      let response = await fetch(`https://siasky.net/${item.skylink}`);
      //console.log('async fetch', response)
      let data = await response.blob();

      let reader = new FileReader();
      await reader.readAsDataURL(data);
      reader.onload = function () {
        console.log(reader.result);
        const base64 = reader.result;
        setFetchedData(base64)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    // Update the output to include the <img> tag with the data URL as the source
    //document.getElementById("TheImageContents").innerHTML = '<h2>The image that you selected</h2><p><img width="200" src="'+TheFileContents+'" /></p>';
    // console.log('async blob', data)
    // let file = new File([data], item.name, item);
  }, [item?.skylink])

  const closeHandler = () => {
    isPreview ? setTogglePreview(!togglePreview) : setItemPreview({}, false)
  }
  const copyLinkHandler = () => {
    navigator.clipboard.writeText(`https://siasky.net/${item.skylink}`);
    setSnackbar({ icon: null, text: "Link has been copied", active: true });
  };
  const handleDelete = () => {
    rm(item.id)
    closeHandler()
  }
  const handlePreview = () => {
    history.push(`/preview/${item.skylink}`)
  }

  console.log('fetchedData', fetchedData)
  return (
    (item &&
     <Container isOpen={togglePreview} isPreview={isPreview}>
       <CancelWrapper onClick={closeHandler}>
         <CancelIcon />
       </CancelWrapper>
       <Name>
         {item.name}
       </Name>
       <Preview>
         <object data={fetchedData ? fetchedData : null}  height="150" ></object>
       </Preview>
       <Tools>
         <div onClick={handlePreview}>
           O
         </div>
         <div>
           R
         </div>
         <div onClick={copyLinkHandler}>
           C
         </div>
         <div onClick={handleDelete}>
           D
         </div>
       </Tools>
       <Privacy>
         {item.isPrivate}
       </Privacy>
       <Meta>
         <div>
           <span>
             Format
           </span>
           <span>
             {item.type || item.fsType}
           </span>
         </div>
         <div>
           <span>
             Created
           </span>
           <span>
             {getDateFromTimeStamp(item.lastModified)}
           </span>
         </div>
         <div>
           <span>
             Size
           </span>
           <span>
             {formatSizeUnits(item.size)}
           </span>
         </div>
       </Meta>
     </Container>))
})

//outline: ${({isPreview}) => isPreview ? 'none' : `2px solid ${colorD.darkPrim10}`};
const Container = s.div`
width: ${({isOpen}) => isOpen ? '16.5em' : '0px'};
opacity: ${({isOpen}) => isOpen ? '1' : '0'};
visibility: ${({isOpen}) => isOpen ? 'vilible' : 'hidden'};
padding: ${({isOpen}) => isOpen ? '0 58px' : '0'};
transition: all .13s ease-out;
background: ${colorD.darkPrim0};
display: flex;
flex-direction: column;
border-top-right-radius: 40px;
border-top-left-radius: 40px;
border-bottom-left-radius: 40px;
color: ${colorD.newDp80};
`
const CancelWrapper = s.div`
display: flex;
justify-content: flex-end;
margin: 30px -26px 0 0;
cursor: pointer;
`

const Name = s.div`
font-size: 20px;
font-weight: 700;
max-width: 280px;
color: ${colorD.lightPrim0};
word-wrap: anywhere;
`
const Preview = s.div`
margin-top: 32px;
> object {
background: ${colorD.lightPrim0};
overflow: hidden;
}
`
const Tools = s.div`
margin-top: 40px;
display: flex;
justify-content: space-between;
`
const Privacy = s.div`
margin-top: 32px;
`
const Meta = s.div`
margin-top: 24px;
display: flex;
flex-direction: column;
> div {
margin-bottom: 16px;
> span {
font-size: 15px;
&:first-of-type {
color: ${colorD.darkPrim60};
padding-right: 8px;
}
}
}
`
