import React, { useState } from 'react'
import FileUploader from "../features/fileSystem/FileUploader";
import FolderCreator from "../features/fileSystem/FolderCreator";
import {
  AddFilesIcon,
  AddFolderIcon,
  MoreHorizontalIcon,
  PlusIcon,

} from "../icons/icons";
import s from "@emotion/styled";
import { colorD } from "../style/global";

export default  React.memo(({ setSnackbar }) => {
  const [isActive, setIsActive] = useState(false);

  const clickHandler = () => {
    setIsActive(!isActive)
  }

  return (
    <Container>
      <MenuBtnWrapper active={isActive}>
        <More onClick={clickHandler} active={isActive}>
          <PlusIcon />
        </More>
        <FileUploader active={isActive}>
          <AddFilesIcon />
        </FileUploader>
        <FolderCreator active={isActive} setSnackbar={setSnackbar}>
          <AddFolderIcon />
        </FolderCreator>
      </MenuBtnWrapper>
    </Container>
  );
})

const Container = s.div`
@media screen and (min-width: 641px) {
position: fixed;
bottom: 24px;
left: 336px;
transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
position: fixed;
left: 50%;
transform: translateX(-50%);
}
`
const buttonStyle = `
width: 88px;
height: 56px;
border-radius: 32px;
backdrop-filter: blur(8px);
background: ${colorD.darkPrim100};
display: flex;
transition: all .2s ease-out;
align-items: center;
justify-content: center;
&:hover {
transition: all .2s ease-out;
svg {
transition: all .2s ease-out;
}
path {
transition: all .2s ease-out;
stroke: #EFF0FB;
}
}
`
const MenuBtnWrapper = s.div`
position: relative;
display: flex;
align-items: center;
transition: all .33s ease-out;
> form {
&:nth-of-type(1) {
transform: ${({active}) => active ? 'translateX(110%)' : 'translateX(0%)'};
}
&:nth-of-type(2) {
transform: ${({active}) => active ? 'translateX(220%)' : 'translateX(0%)'};
}
position: absolute;
${buttonStyle}
}
`;
// visible: ${({active}) => active ? 'visible' : 'collapse'};
// opacity: ${({active}) => active ? '1' : '0'};

// TODO make seperate component

const More = s.div`
cursor: pointer;
z-index: 4;
${buttonStyle}
> svg {
transition: all .2s ease-out;
transform: rotate(${({active}) => active ? '45deg' : '0deg'});
}
`;



//background: ${colorD.glass};
