import React from 'react'
import { getDateFromTimeStamp } from "../../utils/utils";
import s from "@emotion/styled";

export default React.memo(({date}) => (<Date>{getDateFromTimeStamp(date)}</Date>))

const Date = s.div`
padding: 0 4px 0 0;
cursor: pointer;
`;
