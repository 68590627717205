import s from "@emotion/styled";
import { colorD } from "../style/global";

export default function Trademark() {
  return (
    <Footer>
      <div>
        <b>dropmyfile.com</b> v0.1.4
      </div>
      <div>2020 All rights reserved</div>
    </Footer>
  );
}

const Footer = s.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
color: #6A6B7C;
font-size: 12px;
padding: 0 0 24px 24px;
line-height: 140%;
color: ${colorD.darkPrim20};
`;
