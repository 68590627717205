import { v4 as uuidv4 } from "uuid";
import { getCurrentTimeStamp } from "../utils/utils";

const fileSystem = {
  files: {},
  retrievedFiles: {},
  folders: {},
  dirContents: { root: [] },
  itemId: null,
  currentDir: "root",
  currentDirItems: {
    folders: [],
    files: [],
    mixed: [],
  },
  uploadPreviewList: [],
  itemPreview: {
    item: null,
    isOpen: false,
  },
  destinationContainerId: "root",
  totalProgress: 0,
};
const googleFileSystem = {
  files: {},
  folders: {},
  dirContents: { groot: [] },
}

// TODO reduce computation complexity e.g ( use new Set() instead of Object.fromEntries )
const createFileSystemSlice = (set, get) => ({
  fs: fileSystem,
  gfs: googleFileSystem,
  clearFs: () => set((state) => ({fs: fileSystem})),
  saveIdToDirContents: (id, destId) => {
    set((state) => ({
      fs: {
        ...state.fs,
        dirContents: {
          ...state.fs.dirContents,
          [destId]: [...state.fs.dirContents[destId], id],
        },
      },
    }));
    get().setCurrentDirItems(get().fs.currentDir);
  },
  rmIdFromDirContents: (id) => {
    set((state) => ({
      fs: {
        ...state.fs,
        dirContents: {
          ...state.fs.dirContents,
          [state.fs.currentDir]: [
            ...state.fs.dirContents[state.fs.currentDir].filter(
              (x) => x !== id
            ),
          ],
        },
      },
    }));
  },
  filterIdFromCollection: (id, type) => {
    set((state) => ({
      fs: {
        ...state.fs,
        [type]: Object.fromEntries(
          Object.entries(state.fs[type]).filter(([k, v]) => k !== id)
        ),
      },
    }));
  },
  setTotalProgress: (progress) => {
    set(state => ({ fs: { ...state.fs, totalProgress: progress }}))
  },
  rmFolderRecur: (id) => {
    if (get().fs.dirContents[id]) {
      for (const uuid of get().fs.dirContents[id]) {
        get().filterIdFromCollection(uuid, "folders");
        get().filterIdFromCollection(uuid, "files");
        if (get().fs.dirContents[uuid]) {
          get().rmFolderRecur(uuid);
          get().filterIdFromCollection(uuid, "dirContents");
        }
      }
    }
  },
  rmUploadPreview: (index) => {
    console.log('index value', index)
    set((state) => ({
      fs: {
        ...state.fs,
        uploadPreviewList: index !== null ? [
          ...state.fs.uploadPreviewList.filter((x, idx) => idx !== index),
        ] : [],
      },
    }))
  },
  setUploadPreview: async (file) => {
    const id = uuidv4();
    let reader = new FileReader();
    await reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      const base64 = reader.result;
      set((state) => ({
        fs: {
          ...state.fs,
          uploadPreviewList: [
            ...state.fs.uploadPreviewList,
            {
              fileBlob: file,
              fileObj: {
                id,
                lastModified: getCurrentTimeStamp(),
                deviceModified: file.lastModified,
                name: file.name,
                type: file.type,
                size: file.size,
                webkitRelativePath: file.webkitRelativePath,
                fsType: "file",
                isPrivate: false,
              },
              base64,
              status: "ready",
              progress: 0,
            },
          ],
        },
      }));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  },
  setItemPreview: (item, isOpen) => set((state) => ({ fs: { ...state.fs, itemPreview: { item, isOpen } }})),
  setDestinationContainerId: (id) => set((state) => ({ fs: { ...state.fs, destinationContainerId: id } })),
  setCurrentDir: (id) => set((state) => ({ fs: { ...state.fs, currentDir: id } })),
  setCurrentDirItems: (currentFolderId = "root") => {
    set((state) => ({
      fs: {
        ...state.fs,
        currentDirItems: {
          folders: [...state.fs.dirContents[currentFolderId].map(
            (id) => state.fs.folders[id]
          ).filter(Boolean)],
          files: [...state.fs.dirContents[currentFolderId].map(
            (id) => state.fs.files[id]
          ).filter(Boolean)],
          mixed: [
            ...state.fs.dirContents[currentFolderId].map(
              (id) => state.fs.folders[id]
            ).filter(Boolean),
            ...state.fs.dirContents[currentFolderId].map(
              (id) => state.fs.files[id]
            ).filter(Boolean),
          ]
        },
      },
    }));
  },
  mkdir: (name, isPrivate = true, destId) => {
    const id = uuidv4();
    set((state) => ({
      fs: {
        ...state.fs,
        folders: {
          ...state.fs.folders,
          [id]: {
            id,
            name,
            fsType: "folder",
            lastModified: getCurrentTimeStamp(),
            private: isPrivate,
          },
        },
        dirContents: { ...state.fs.dirContents, [id]: [] },
      },
    }));
    get().saveIdToDirContents(id, destId || get().fs.currentDir);
  },
  touch: (item, destId) => {
    set((state) => ({
      fs: {
        ...state.fs,
        files: { ...state.fs.files, [item.id]: item },
      },
    }));
    get().saveIdToDirContents(item.id, destId || get().fs.currentDir);
  },
  rm: (id) => {
    get().rmFolderRecur(id);
    get().filterIdFromCollection(id, "folders");
    get().filterIdFromCollection(id, "files");
    get().filterIdFromCollection(id, "dirContents");
    get().rmIdFromDirContents(id);
    get().setCurrentDirItems(get().fs.currentDir);
  },
  mv: (id, destId) => {
    get().rmIdFromDirContents(id);
    get().saveIdToDirContents(id, destId);
  },
  rename: (id, name, type) => {
    console.log('id name type', id, name, type)
    set((state) => ({
      fs: {
        ...state.fs,
        [`${type}s`]: {
          ...state.fs[`${type}s`],
          [id]: { ...state.fs[`${type}s`][id], name },
        },
      },
    }));
    get().setCurrentDirItems(get().fs.currentDir);
  },
});

export default createFileSystemSlice;

// TODO undone
//

// const selectDirContents = (fs, containerId) => {
//   const ids = fs.dirContents[containerId];
//   const files = ids.map((id) => fs.files[id]).filter(Boolean);
//   const dirs = ids.map((id) => fs.directories[id]).filter(Boolean);
//   return [...dirs, ...files];
// };

// //export const restoreFsItem = () => {
// //TODO encrypiton
// //TODO provide skylink + encr passphrase
// // how to restore recursively
// //}
//
//
// done
//
// export const renameFsItem = (fs, itemId, name) => {
//   let subj = fs.directories[itemId] || fs.files[itemId];
//   subj.name = name;
//   return Object.assign({}, fs);
// };
// export const moveFsItem = (fs, itemId, destId, containerId) => {
//   rmIdFromDirContents(fs, itemId, containerId);
//   saveIdToDirContents(fs, itemId, destId);
//   return Object.assign({}, fs);
// };
// export const deleteFsItem = (fs, itemId, containerId) => {
//   if (Array.isArray(itemId)) {
//     // TODO selection multiple items
//     //const deleteItems = () => {}
//     //console.log('array returned', itemId)
//   } else {
//     if (!!fs.dirContents[itemId]) {
//       deleteDirRecur(fs, itemId);
//       rmDirFromDirs(fs, itemId);
//       rmDirFromDirContents(fs, itemId);
//       rmIdFromDirContents(fs, itemId, containerId);
//       return Object.assign({}, fs);
//     } else {
//       rmIdFromDirContents(fs, itemId, containerId);
//       rmFileFromFiles(fs, itemId);
//       rmDirFromDirs(fs, itemId);
//       return Object.assign({}, fs);
//     }
//   }
// };
//
// export const saveFsItem = (fs, item, containerId) => {
//   fs.files = Object.assign(fs.files, { [item.id]: item });
//   saveIdToDirContents(fs, item.id, containerId);
//   return Object.assign({}, fs);
// };

// const saveIdToDirContents = (fs, itemId, containerId) =>
//   (fs.dirContents[containerId] = [...fs.dirContents[containerId], itemId]);
//
//
// const rmFileFromFiles = (fs, itemId) =>
//   (fs.files = Object.fromEntries(Object.entries(fs.files).filter(([k, v]) => k !== itemId)));

// const rmDirFromDirs = (fs, itemId) =>
//   (fs.directories = Object.fromEntries(Object.entries(fs.directories).filter(([k, v]) => k !== itemId)));
//
// const rmDirFromDirContents = (fs, itemId) => {
// delete
//   return fs;
// };
// const rmIdFromDirContents = (fs, itemId, containerId) =>
//   (fs.dirContents[containerId] = fs.dirContents[containerId].filter(
//     (x) => x !== itemId
//   ));
//
// const deleteDirRecur = (fs, itemId) => {
//   for (const uuid of fs.dirContents[itemId]) {
//     rmFileFromFiles(fs, uuid);
//     rmDirFromDirs(fs, uuid);
//     if (!!fs.dirContents[uuid]) {
//       deleteDirRecur(fs, uuid);
//       rmDirFromDirContents(fs, uuid);
//     }
//   }
//   return fs;
// };
//
