import React, { useEffect } from "react";
import s from "@emotion/styled";
import { colorD, breakpoint } from "../../style/global";
import { SmileFaceIcon } from "../../icons/icons";

export default React.memo(({ children, snackbar, setSnackbar }) => {
  const handleClick = (e) => {
    setSnackbar({ active: false, icon: snackbar.icon, text: snackbar.text });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSnackbar({ text: snackbar.text, active: false, icon: snackbar.icon });
    }, 3000);
    return () => clearTimeout(timeout);
  }, [snackbar.active]);
  return (
    <Container onClick={handleClick} isActive={snackbar.active}>
      <IconWrapper>
        {snackbar.icon ? snackbar.icon : <SmileFaceIcon />}
      </IconWrapper>
      {snackbar.text}
    </Container>
  );
});

const Container = s.div`
z-index: 10;
border-radius: 12px;
position: fixed;
bottom: 100px;
left: 50%;
background: ${colorD.darkPrim40};
font-size: 14px;
font-weight: 500;
padding: 17px 20px;
color: ${colorD.lightPrim0};
height: 14px;
width: max-content;
max-width: 90vw;
display: flex;
align-items: center;
box-shadow: -2px 20px 22px 8px rgba(14,17,14, .18);
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transform: ${(props) =>
  props.isActive ? "translate(-50% , 24px)" : "translate(-50%, 60px)"};
transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
@media screen and (min-width: ${breakpoint.mds}) {
transform: ${(props) =>
  props.isActive ? "translateY(24px)" : "translateY(0px)"};
}
`;

const IconWrapper = s.div`
margin-right: 10px;
display: flex;
align-items: center;
justify-content: center;
svg,path {
stroke: ${colorD.lightPrim0};
}
`;
