import s from "@emotion/styled";
import { EmptyStorageIll } from "../icons/illustrations";
import { color } from "../style/global";

export default function StorageUsageDescription() {
  return (
    <StorageDescription>
      <span>This is your Upload storage.</span>
      <span>
        Drop files of entire directory here, then share it with <br /> people.
      </span>
      <EmptyStorageIll />
      <span>Drop here</span>
    </StorageDescription>
  );
}

const StorageDescription = s.div`
margin: 52px 0 46px 0;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
color: ${color.dp80};
> svg {
 margin: 46px 0 30px 0;
}
> span:last-of-type {
font-size: 20px;
}
`;
