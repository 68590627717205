import React from "react";
import s from "@emotion/styled";
import { colorD } from "../../style/global";

export default React.memo(({ name, items }) =>
  (<Container>
     <span>{name}</span>
     <span>{!!items ? items.length : 0}</span>
   </Container>)
);

const Container = s.div`
font-size: 14px;
margin: 14px 16px;
color: ${colorD.darkPrim40};
font-weight: 600;
> span:nth-of-type(2) {
padding-left: 4px;
color: ${colorD.darkPrim20};
}
`;
