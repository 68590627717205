import React, { useState, useEffect } from "react";
import s from "@emotion/styled";
import { colorD, breakpoint } from "../../style/global";
import {
  EnvelopFrameIcon,
  KeyFrameIcon,
  ArrowRightIcon,
} from "../../icons/icons";

import useStore from "../../store/useStore";
export default React.memo(({ setRegPage, regPage, setRegModal }) => {
  const { genSeed } = useStore();
  const [showPage, setShowPage] = useState(false);
  // TODO what is this????
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const handleLoginSwitch = () => {
    setRegPage(!regPage);
  };
  const handleGenModal = () => {
    genSeed();
    setRegModal(true);
  };
  return (
    <Container isActive={showPage}>
      <Wrapper isActive={showPage}>
        <Title>Create an account</Title>
        <SubTitle>
          Already have? <span onClick={handleLoginSwitch}>Log in</span>
        </SubTitle>
        <SubTitle>Select registration type</SubTitle>
        <RegType onClick={handleGenModal}>
          <FrameIconWrapper>
            <KeyFrameIcon />
          </FrameIconWrapper>
          <RegTextWrapper>
            <span>Generate Seed</span>
            <span>Create secure account with secret passphrase</span>
          </RegTextWrapper>
          <IconWrapper>
            <ArrowRightIcon />
          </IconWrapper>
        </RegType>
        <RegType style={{ cursor: "not-allowed" }}>
          <FrameIconWrapper>
            <EnvelopFrameIcon />
          </FrameIconWrapper>
          <RegTextWrapper>
            <span>Email & Password</span>
            <span>Common register with email and password</span>
          </RegTextWrapper>
          <IconWrapper>
            <ArrowRightIcon />
          </IconWrapper>
        </RegType>
      </Wrapper>
    </Container>
  );
});

const Container = s.div`
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 4;
background: #1F202C;
border-bottom-left-radius: 72px;
border-top-left-radius: 72px;
font-size: 14px;
font-weight: 500;
color: ${colorD.lightPrim0};
flex: 0 ${(props) => (props.isActive ? "50%" : "0")};
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transition: all 0.4s ease;
@media screen and (max-width: ${breakpoint.md}px) {
flex: 0 ${(props) => (props.isActive ? "100%" : "0")};
}

`;
const Wrapper = s.div`
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transition: all 0.4s ease;
`;
const Title = s.div`
letter-spacing: .014em;
font-size: 26px;
font-weight: 500;
color: ${colorD.lightPrim0};
margin-bottom: 5px;
`;
const SubTitle = s.div`
font-size: 16px;
font-weight: 400;
color: ${colorD.darkPrim80};
> span {
color: ${colorD.accent100};
margin-left: 4px;
cursor: pointer;
}
margin-bottom: 32px;
`;
const RegType = s.div`
display: flex;
width: 340px;
cursor: pointer;
align-items: center;
padding: 26px 20px;
border: 1px solid ${colorD.darkPrim20};
transition: all .4s ease;
margin-bottom: 24px;
border-radius: 24px;
&:hover {
transition: all .4s ease;
background: ${colorD.darkPrim20};
}
@media screen and (max-width: ${breakpoint.sm}px) {
width: 280px;
}
`;
//@media screen and (min-width: ${breakpoint.sm}) {
const RegTextWrapper = s.div`
display: flex;
flex-direction: column;
max-width: 202px;
> span {
letter-spacing: .014em;
font-size: 16px;
&:first-of-type {
font-weight: 500;
color: #fdfdfd;
}
color: ${colorD.darkPrim80};
}
`;
const FrameIconWrapper = s.div`
margin-right: 20px;
`;
const IconWrapper = s.div`
margin-left: auto;
`;

// const Form = s.form`
// display: flex;
// flex-direction: column;
// `;
// const FormTitle = s.label`
// font-size: 11px;
// font-weight: 500;
// color: ${colorD.darkPrim60};
// margin-bottom: 12px;
// `;
// const Input = s.input`
// border-radius: 16px;
// background: ${colorD.darkPrim20};
// height: 46px;
// border: none;
// text-indent: 16px;
// color: ${colorD.lightPrim0};
// &::placeholder {
// color: ${colorD.lightPrim80};
// }
// margin-bottom: 24px;
// `;
// const ContinueBtn = s.button`
// border: none;
// font-weight: 500;
// color: ${colorD.lightPrim0};
// background: ${colorD.accent100};
// width: 115px;
// height: 42px;
// align-self: flex-end;
// border-radius: 16px;
// `;
