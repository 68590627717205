import React from 'react';
import s from "@emotion/styled";
import { InfoModalImg } from "../icons/icons";
import { colorD } from "../style/global";

export default  React.memo(({ isActive, setIsActive }) => {
  const handleDone = () => {
    setIsActive(false);
  };
  return (
    <Container isActive={isActive}>
      <InfoModalImg />
      <TextWrapper>
        <TitleGreet>Welcome to the dropmyfile.com!</TitleGreet>
        <InfoText>
          1. First, select files from your device that you want to archive.
        </InfoText>
        <InfoText>
          2. Wait untill all of your files appear in a preview state.
        </InfoText>
        <InfoText>
          3. Press "Upload & share" button to create a new archive.
        </InfoText>
        <InfoText>4. Finally copy and share your upload link.</InfoText>
        <InfoTextB>That’s it!</InfoTextB>
        <InfoText>
          You can store encrypted private files with dropmyfile.com with your phrase.
        </InfoText>
      </TextWrapper>
      <InfoTextC onClick={handleDone}>Done</InfoTextC>
    </Container>
  );
});

const Container = s.div`
@media screen and (max-width: 408px) {
width: 352px;
top: 48px;
}
@media screen and (max-width: 358px) {
width: 338px;
top: 50px;
}
overflow: hidden;
display: flex;
flex-direction: column;
position: absolute;
z-index: 10;
background: ${colorD.darkPrim20};
border-radius: 24px;
top: 120px;
margin: 0 auto;
left: 0;
right: 0;
width: 400px;
max-width: 500px;
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transform: ${(props) =>
  props.isActive ? "translateY(100px)" : "translateY(0px)"};
transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
`;
const TitleGreet = s.div`
font-weight: 500;
font-size: 20px;
color: ${colorD.darkPrim90};
margin: 32px 0 16px 0;
`;
const TextWrapper = s.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width: 338px;
margin: 0 auto;
`;

//TODO make it big full width
const InfoTextC = s.div`
flex: 1;
color: ${colorD.darkPrim80};
font-weight: 500;
font-size: 15px;
margin-top: 48px;
margin-bottom: 32px;
margin-right: 36px;
align-self: flex-end;
cursor: pointer;
`;
const InfoTextB = s.div`
flex: 1;
color: ${colorD.darkPrim80};
font-size: 14px;
margin-top: 20px;
`;
const InfoText = s.div`
flex: 1;
color: ${colorD.darkPrim80};
font-size: 14px;
margin: 2px 0;
`;

