import React from "react";
import s from "@emotion/styled";
import { colorD } from "../style/global";
import { useContextMenu } from "../utils/hooks/useContextMenu";
import useStore from "../store/useStore";
import { downloadFile, openFile } from "../utils/skynet";
import {
  TabIcon,
  RetrieveIcon,
  TrshIcon,
  LinkIcon,
  InfoIcon,
  PenIcon,
} from "../icons/icons";

export default React.memo(
  ({
    outerRef,
    setSnackbar,
    item,
    setShowRename,
    setRenItemId,
  }) => {
    const { rm, setItemPreview } = useStore();
    const { xPos, yPos, menu } = useContextMenu(outerRef);
    const deleteHandler = async () => {
      rm(item.id);
      setSnackbar({ icon: null, text: "Item has been deleted", active: true });
    };
    const copyLinkHandler = (link) => {
      navigator.clipboard.writeText(`https://siasky.net/${link}`);
      setSnackbar({ icon: null, text: "Link has been copied", active: true });
    };
    const renameHandler = async () => {
      setShowRename(true);
      setRenItemId(item.id);
    };
    const itemPreviewHandler = async () => {
      setItemPreview(item, true)
    }
    return (
      menu && (
        <ContextMenu style={{ top: yPos, left: xPos }}>
          <MenuItemWrapper onClick={itemPreviewHandler}>
            <InfoIcon />
            <span>Info</span>
          </MenuItemWrapper>
          <MenuItemWrapper onClick={renameHandler}>
            <PenIcon />
            <span>Rename</span>
          </MenuItemWrapper>
          {item.type !== "folder" && (
            <>
              <MenuItemWrapper onClick={() => copyLinkHandler(item.skylink)}>
                <LinkIcon />
                <span>Copy link</span>
              </MenuItemWrapper>
              <MenuItemWrapper onClick={() => downloadFile(item)}>
                <RetrieveIcon />
                <span>Download</span>
              </MenuItemWrapper>
              <MenuItemWrapper onClick={() => openFile(item)}>
                <TabIcon />
                <span>In a new tab</span>
              </MenuItemWrapper>
            </>
          )}

          <MenuItemWrapper onClick={deleteHandler}>
            <TrshIcon />
            <span>Delete</span>
          </MenuItemWrapper>
        </ContextMenu>
      )
    );
  }
);

const ContextMenu = s.div`
position: fixed;
width: 160px;
background: ${colorD.darkPrim100};
color: ${colorD.darkPrim80};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 0;
padding: 8px 0;
border-radius: 12px;
list-style: none;
z-index: 1;
`;

const MenuItemWrapper = s.div`
border-radius: 8px;
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
width: 144px;
height: 40px;
min-height: 40px;
flex: 1;
transition: all .4s ease;
path {
transition: all .4s ease;
}
 > svg {
 padding: 10px;
 }
&:hover {
transition: all .4s ease;
color: ${colorD.lightPrim0};
background: ${colorD.darkPrim10};
border-radius: 8px;
`;
