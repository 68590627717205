import { useState } from "react";
import s from "@emotion/styled";
import { colorD } from "../../style/global";
import { CancelIcon } from "../../icons/icons";
import useStore from "../../store/useStore";

export default function FolderCreator({ setSnackbar, children }) {
  const [name, setName] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [isPrivate, setPrivate] = useState(false);

  const { mkdir } = useStore();

  // TODO handle form validation
  const submitHandler = async (e) => {
    if (showInput && name) {
      mkdir(name, isPrivate);
      setShowInput(false);
      setName("");
      setSnackbar({
        icon: null,
        text: "A new folder has been added",
        active: true,
      });
    }
    if (!name && !showInput) {
      setShowInput(true);
    }
  };
  const openModal = () => {
    !showInput && setShowInput(true);
  };
  const cancelHandler = () => {
    setShowInput(false);
    setName("");
  };
  const handleChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  return (
    <Container onClick={openModal} onSubmit={submitHandler}>
      {showInput && (
        <CreateFolder isActive={showInput} htmlFor="folder-upload">
          <Title>New folder</Title>
          <Input
            autoFocus
            id="folder-upload"
            type="text"
            value={name}
            onChange={handleChange}
            placeholder="Name a folder"
          />
          <CheckboxGroup>
            <input type="checkbox" onClick={() => setPrivate(!isPrivate)} />
            <label>Make private</label>
          </CheckboxGroup>
          <BtnGroup onSubmit={submitHandler}>
            <FolderModalBtn
              onClick={submitHandler}
              name={name}
              disabled={!name}
            >
              Save
            </FolderModalBtn>
            <FolderModalBtn onClick={() => cancelHandler()}>
              Cancel
            </FolderModalBtn>
          </BtnGroup>
        </CreateFolder>
      )}
      {showInput ? (
        <IconWrapper onClick={() => cancelHandler()}>
          <CancelIcon />
        </IconWrapper>
      ) : (
        <IconWrapper>{children}</IconWrapper>
      )}
    </Container>
  );
}
const CreateFolder = s.label`
opacity: ${(props) => (props.isActive ? "1" : "0")};
visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
transform: ${(props) =>
  props.isActive ? "translateY(0px)" : "translateY(50px)"};
transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
display: flex;
justify-content: space-around;
flex-direction: column;
width: 252px;
min-width: 252px;
height: 170px;
border-radius: 16px;
padding: 20px 24px 10px 24px;
background: ${colorD.darkPrim10};
z-index: 2;
@media screen and (max-width: 640px) {
position: fixed;
bottom: 72px;
left: 50%;
transform: translateX(-50%);
}
@media screen and (min-width: 641px) {
position: fixed;
left: 0;
bottom: 0px;
transform: translateX(-50%);
transform: translateY(-50%);
}
align-items: center;
input[type="text"] {
}
`;

const Container = s.form`
cursor: pointer;
`;
const IconWrapper = s.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
border-radius: 16px;
transition: all .4s ease;
`;
const Title = s.span`
align-self: flex-start;
color: ${colorD.lightPrim0};
font-weight: 700;
margin-bottom: 20px;
`;
const FolderModalBtn = s.button`
cursor: pointer;
padding: 10px 16px;
transition: all .4s ease;
border-radius: 16px;
background: ${colorD.darkPrim10};
border: none;
font-weight: 500;
color: ${colorD.darkPrim80};
margin-left: 8px;
&:hover, &:active {
&:nth-of-type(1) {
cursor: ${(props) => (!props.name ? "not-allowed" : "pointer")};
color: ${(props) => (!props.name ? colorD.darkPrim80 : colorD.lightPrim0)};
background:  ${(props) => (!props.name ? "none" : colorD.darkPrim40)};
}
background: ${colorD.darkPrim40};
color: ${colorD.lightPrim0};
transition: all .4s ease;
}
`;

// border: 0;
// clip: rect(0 0 0 0);
// clippath: inset(50%);
// height: 1px;
// margin: -1px;
// overflow: hidden;
// padding: 0;
// position: absolute;
// white-space: nowrap;
// width: 1px;
const CheckboxGroup = s.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 100%;
label {
color: ${colorD.darkPrim40};
}
> input {
border: 1px solid ${colorD.accent100};
margin-right: 8px;
}
`;

const BtnGroup = s.div`
align-self: flex-end;
display: flex;
> button:nth-of-type(1) {
order: 2;
}
`;
const Input = s.input`
flex: 0 100%;
width: 100%;
text-indent: 8px;
max-height: 40px;
min-height: 40px;
margin-bottom: auto;
border: ${colorD.accent100} 1px solid;
background: ${colorD.darkPrim10};
border-radius: 8px;
font-size: 15px;
color: ${colorD.lightPrim0};
font-weight: 500;
transition: all .4s ease;
&:focus {
box-shadow: 0 0 4px 8px rgba(87, 84, 247, 0.1);
transition: all .4s ease;
}
@media screen and (max-width: 640px) {
}
`;
