import React, { useState } from 'react';
import { Document, Outline, Page } from 'react-pdf';


export default function Test({file}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState(null);

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

    console.log('pdf', pdf)
  // function onDocumentLoadSuccess(pdf) {
  // }
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
    console.log('pdf on looad', pdf)
    setPdf(pdf);
  }

  function onOutlineSuccess(outline) {
    console.log('outline on looad', outline)
  }
  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
      <Outline onItemClick={onItemClick} onLoadSuccess={onOutlineSuccess} onLoadError={(error) => alert('Error while retrieving the outline! ' + error.message)}/ >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>
  );
}
