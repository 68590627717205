export const breakpoint = {
  sm: 375,
  smm: 414,
  md: 640,
  mds: 641,
  lg: 948,
};

// multiple nx8
export const m = {
  x1: 8,
  x1h: 12,
  x2: 16,
  x2h: 20,
  x3: 24,
  x4: 32,
  x5: 40,
  x6: 48,
};

export const colorD = {
  darkPrim120: "#0F1017",
  darkPrim100: "#1D1E29",
  darkPrim0: "#242533",
  darkPrim10: "rgba(56, 57, 75, 0.5)",
  darkPrim20: "#38394B",
  darkPrim40: "#484964",
  darkPrim60: "#57576C",
  darkPrim80: "#808192",
  darkPrim90: "#ABACBE",
  lightPrim0: "#EFF0FB",
  accent100: "#5754F7",
  newDp80: "#808192",
  glass: "rgba(56, 57, 75, 0.7)",
};

export const color = {
  dp80: "#6A6B7C",

};
